import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import le from "../../apis/letter-emojis.json";

const YachtName = ({ yacht, createLink }) => {

  const emojiFlag = yacht => {
    if (yacht.nationality) {
      const code = yacht.nationality.toUpperCase().split('');
      return le[code[0]].emoji + '' + le[code[1]].emoji;
    }
    return null;
  }

  return !!createLink 
      ? <Link to={`/yachts/${yacht.id}`}>{emojiFlag(yacht)} {yacht.name}</Link>
      : <Fragment>{emojiFlag(yacht)} {yacht.name}</Fragment>
}

export default YachtName;