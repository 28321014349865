import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Checkbox, Icon, Search } from "semantic-ui-react";
import fu from "../common/formUtils";

const CruiseCrewForm = ({
  crewmembers,
  setCrewmembers,
  errors,
  onCrewSearch,
  onAddCrewPerson,
  onDeleteCrewmember,
  canAddToCrew,
  personMapper,
  parsePerson,
  isCaptain,
}) => {
  const [crewMemberLoading, setCrewMemberLoading] = useState(false);
  const [crewSearchResults, setCrewSearchResults] = useState([]);
  const [crewSearchValue, setCrewSearchValue] = useState("");

  const onCrewResultSelect = (event, data) => {
    addCrewMember(data.result);
  };

  const onCrewSearchChange = (event, { value }) => {
    setCrewSearchValue(value);
    setCrewMemberLoading(true);
    onCrewSearch(value).then((results) => {
      setCrewMemberLoading(false);
      setCrewSearchResults(results.filter(canAddToCrew).map(personMapper));
    });
  };

  const crewmemberMiles = (personId) => {
    return crewmembers.find((a) => a.id === personId).milles + "";
  };

  const crewmemberFullDays = (personId) => {
    return crewmembers.find((a) => a.id === personId).fulldays + "";
  };

  const crewmemberPointsEarned = (personId) => {
    return crewmembers.find((a) => a.id === personId).pointsearned + "";
  };

  const crewmemberPointsSpent = (personId) => {
    return crewmembers.find((a) => a.id === personId).pointsspent + "";
  };

  const milesChange = (personId) => {
    return (e) => {
      const cm = [...crewmembers];
      cm.find((a) => a.id === personId).milles = numberValue(e.target.value);
      setCrewmembers(cm);
    };
  };

  const fullDaysChamge = (personId) => {
    return (e) => {
      const cm = [...crewmembers];
      cm.find((a) => a.id === personId).fulldays = numberValue(e.target.value);
      setCrewmembers(cm);
    };
  };

  const pointsEarnedChange = (personId) => {
    return (e) => {
      const cm = [...crewmembers];
      cm.find((a) => a.id === personId).pointsearned = numberValue(e.target.value);
      setCrewmembers(cm);
    };
  };

  const pointsSpentChange = (personId) => {
    return (e) => {
      const cm = [...crewmembers];
      cm.find((a) => a.id === personId).pointsspent = numberValue(e.target.value);
      setCrewmembers(cm);
    };
  };

  const crewmemberRanked = (personId) => {
    return !!crewmembers.find((a) => a.id === personId).image;
  };

  const rankedChange = (personId) => {
    return (e) => {
      const cm = [...crewmembers];
      const member = cm.find((a) => a.id === personId);
      member.image = !!member.image ? "" : "1";
      setCrewmembers(cm);
    };
  };

  const onDeleteCrewmemberClick = (personId) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      setCrewmembers(crewmembers.filter((c) => c.id !== personId));
      onDeleteCrewmember(personId);
    };
  };

  const crewResultRenderer = ({ title }) => <div>{title}</div>;

  const onAddCrewPersonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const parsed = parsePerson(crewSearchValue);
    if (parsed === null) {
      console.warn("Cannot parse " + crewSearchValue);
      return null;
    }

    onAddCrewPerson(parsed)
      .then((newPerson) => {
        if (newPerson !== undefined) addCrewMember(personMapper(newPerson));
      })
      .catch((e) => console.error("caught", e));
  };

  const addCrewMember = (crewMember) => {
    setCrewSearchValue("");
    setCrewSearchResults([]);
    setCrewmembers(crewmembers.concat([crewMember]));
  };

  const numberValue = (value) => {
    return value.replace(/[^0-9,.]/i, "").replace(",", ".");
  };

  const getCrewmembers = () => {
    return crewmembers.map((p, i) => (
      <tr key={p.id}>
        <td>{i + 1}.</td>
        <td>
          {isCaptain(p.id) ? "kpt. " : ""}
          {p.title}
        </td>
        <td>
          <FormattedMessage id="field.miles" defaultMessage="miles">
            {(msg) => (
              <input
                type="text"
                placeholder={msg}
                value={crewmemberMiles(p.id)}
                onChange={milesChange(p.id)}
              />
            )}
          </FormattedMessage>
        </td>
        <td>
          <FormattedMessage id="field.fullDays" defaultMessage="full days">
            {(msg) => (
              <input
                type="text"
                placeholder={msg}
                value={crewmemberFullDays(p.id)}
                onChange={fullDaysChamge(p.id)}
              />
            )}
          </FormattedMessage>
        </td>
        <td>
          <FormattedMessage id="field.pointsEarned" defaultMessage="points earned">
            {(msg) => (
              <input
                type="text"
                placeholder={msg}
                value={crewmemberPointsEarned(p.id)}
                onChange={pointsEarnedChange(p.id)}
              />
            )}
          </FormattedMessage>
        </td>
        <td>
          <FormattedMessage id="field.pointsSpent" defaultMessage="points spent">
            {(msg) => (
              <input
                type="text"
                placeholder={msg}
                value={crewmemberPointsSpent(p.id)}
                onChange={pointsSpentChange(p.id)}
              />
            )}
          </FormattedMessage>
        </td>
        <td>
          <Checkbox
            toggle
            label="uwzględniaj"
            checked={crewmemberRanked(p.id)}
            onChange={rankedChange(p.id)}
          />
        </td>
        <td>
          <button
            className="ui red button"
            onClick={onDeleteCrewmemberClick(p.id)}
          >
            <FormattedMessage id="action.delete" defaultMessage="Delete" />
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div style={{ marginTop: 2 + "em" }}>
      <h2 className="ui header">
        <FormattedMessage id="field.crew" defaultMessage="Crew" />
      </h2>

      <div className={fu.classForField("crew", "field", errors)}>
        <label htmlFor="crewSearch">
          <FormattedMessage
            id="field.crewmember"
            defaultMessage="Find crew member"
          />
        </label>
        {fu.fieldErrors("firstName", errors)}
        {fu.fieldErrors("lastName", errors)}
        {fu.fieldErrors("crew", errors)}
        <Search
          id="crewSearch"
          loading={crewMemberLoading}
          onResultSelect={onCrewResultSelect}
          onSearchChange={onCrewSearchChange}
          results={crewSearchResults}
          value={crewSearchValue}
          resultRenderer={crewResultRenderer}
          minCharacters={3}
          noResultsMessage={
            <Button
              primary
              icon
              labelPosition="left"
              onClick={onAddCrewPersonClick}
            >
              <Icon name="plus" />
              <FormattedMessage
                id="action.addNewPerson"
                defaultMessage="Add new person"
              />
            </Button>
          }
        />
      </div>

      <table className="ui celled padded table">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="header.no" defaultMessage="No." />
            </th>
            <th>
              <FormattedMessage
                id="header.crewmember"
                defaultMessage="Crew member"
              />
            </th>
            <th>
              <FormattedMessage id="header.miles" defaultMessage="Miles" />
            </th>
            <th>
              <FormattedMessage
                id="header.fullDays"
                defaultMessage="Full days"
              />
            </th>
            <th>
              <FormattedMessage id="header.pointsEarned" defaultMessage="Points earned" />
            </th>
            <th>
              <FormattedMessage id="header.pointsSpent" defaultMessage="Points spent" />
            </th>
            <th>
              <FormattedMessage id="header.ranked" defaultMessage="Ranked" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>{getCrewmembers()}</tbody>
      </table>
    </div>
  );
};

export default CruiseCrewForm;
