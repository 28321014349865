import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import fu from "../common/formUtils";

const UserForm = ({ 
  user, 
  title, 
  errors,
  errorMessages,
  onSave 
}) => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [registered, setRegistered] = useState(false);
  const [email, setEmail] = useState("");
  const [gdprConsent, setGdprConsent] = useState(false);
  
  useEffect(() => {
    setFirstName(user.firstName || '');
    setLastName(user.lastName || '');
    setEmail(user.email || '');
    setGdprConsent(user.gdprConsent || false);
    // setRegistered(user.registrationToken === null)
  }, [user]);

  const onSaveButtonClicked = () => {
    onSave({
      firstName: firstName,
      lastName: lastName,
      email: email,
      gdprConsent: gdprConsent
    }, user.id);
  }

  const anyErrors = () => {
    return Object.keys(errors).length > 0;
  }

  const onGdprConsentChange = (e) => {
    setGdprConsent(!gdprConsent);
  }

  return (
    <div className="ui component">
      <h1 className="ui header">
        <FormattedMessage
          id={`title.${title}`}
          defaultMessage="Add a new person"
        />
      </h1>
      <div className="ui segment">

        <div className="ui error message" hidden={!anyErrors()}>
          <div className="header">
            <FormattedMessage
              id="msg.errorsOccured"
              defaultMessage="There were some errors"
            />
          </div>
          <ul className="list">
            {errorMessages
              .flatMap((msg) => msg)
              .map((mmsg, i) => <li key={i}>{mmsg}</li>)}
          </ul>
        </div>

        <form className={fu.classForForm("ui form", anyErrors())} onSubmit={e => e.preventDefault()}>

          <div className={fu.classForField('firstName', 'field', errors)}>
            <label htmlFor="firstName"><FormattedMessage id="field.firstName" defaultMessage="First name" /></label>
            {fu.fieldErrors('firstName', errors)}
            <input type="text" name="firstName" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} />
          </div>

          <div className={fu.classForField('lastName', 'field', errors)}>
            <label htmlFor="lastName"><FormattedMessage id="field.lastName" defaultMessage="Last name" /></label>
            {fu.fieldErrors('lastName', errors)}
            <input type="text" name="lastName" id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} />
          </div>

          <div className="field">
            <div className="ui checkbox" onClick={onGdprConsentChange}>
              <input type="checkbox" className="hidden" checked={gdprConsent} onChange={onGdprConsentChange} />
              <label><FormattedMessage id="field.gdprConsent" defaultMessage="GDPR Consent" /></label>
            </div>
          </div>

          {/* 
          <div className={fu.classForField('gdprConsent', 'field', errors)}>
            <label htmlFor="gdprConsent"><FormattedMessage id="field.gdprConsent" defaultMessage="Email" /></label>
            {fu.fieldErrors('gdprConsent', errors)}
            <input type="text" name="gdprConsent" id="gdprConsent" value={gdprConsent} onChange={e => setGdprConsent(e.target.value)} />
          </div> 
          */}

          <div className={fu.classForField('email', 'field', errors)}>
            <label htmlFor="email"><FormattedMessage id="field.email" defaultMessage="Email" /></label>
            {fu.fieldErrors('email', errors)}
            <input type="text" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
          </div>

          <div className="field">
            <button className="ui primary button" onClick={onSaveButtonClicked}><FormattedMessage id="action.save" defaultMessage="Save" /></button>
          </div>

        </form>
        
      </div>
    </div>
  );
};

export default UserForm;