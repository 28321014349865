import React from "react";
import { FormattedMessage } from "react-intl";
import { hasRole } from "../../core/Auth";
import UserRow from "./UserRow";

const UsersTable = ({ users, onUserDeleteClick, onSendCodeClick }) => {

  const userRows = users.map(user => <UserRow key={user.id} user={user} onUserDeleteClick={onUserDeleteClick} onSendCodeClick={onSendCodeClick} />);
  
  return (
    <table className="ui celled padded table" style={{ marginTop: 0 }}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="header.firstName" defaultMessage="First name" />
          </th>
          <th>
            <FormattedMessage id="header.lastName" defaultMessage="Last name" />
          </th>
          <th>
            <FormattedMessage id="header.email" defaultMessage="Email" />
          </th>
          <th>
            <FormattedMessage id="header.points" defaultMessage="Points" />
          </th>
          <th>
            <FormattedMessage id="header.gdprConsent" defaultMessage="Zgoda RODO" />
          </th>
          {hasRole('ADMIN') ? <th></th> : null}
        </tr>
      </thead>
      <tbody>{userRows}</tbody>
    </table>
  );
};

export default UsersTable;