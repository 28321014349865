import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./components/App";
import English from "./lang/en.json";
import Polish from "./lang/pl.json";
import { Auth0Provider } from "@auth0/auth0-react";

const env = process.env.REACT_APP_ENV_NAME;
if (env === 'production') {
  console.log = () => {}
}
if (env !== 'development') {
  Sentry.init({
    dsn: "https://43a5e30dbc1e4d648b656daab24b9d51@o572095.ingest.sentry.io/5897854",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: env
  });
}

const locale = navigator.language;
let lang;
if (locale.substring(0, 2).toLowerCase() === "pl") {
  lang = Polish;
} else {
  lang = English;
}

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    scope='read:people'
  >
    <IntlProvider locale={locale} messages={lang}>
      <App />
    </IntlProvider>
  </Auth0Provider>,
  document.querySelector("#root")
);
