import axios from 'axios';

const milesrankAxios = axios.create({
  baseURL: process.env.REACT_APP_MILESRANK_API_URL
});

const setAxiosTokenInterceptor = (accessToken) => {
  milesrankAxios.interceptors.request.use(config => {
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config;
  })
}

export default milesrankAxios;
export { milesrankAxios, setAxiosTokenInterceptor };