import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const Statistic = ({ value, messageId, messageValues, defaultMessage, formatAsNumber, className, style }) => {


  const stat = (value) => (
    <div className={`statistic ${className}`} style={style}>
      <div className="value">
        { formatAsNumber ? <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} /> : value }
      </div>
      <div className="label">
        <FormattedMessage
          id={messageId}
          values={messageValues}
          defaultMessage={defaultMessage || "default message"}
        />
      </div>
    </div>

  );

  return typeof value === "undefined" ? null : stat(value);

};

export default Statistic;