import React, { useState, Fragment } from "react";
import { Navigate } from "react-router";
import yachts from "../../apis/yachts";
import YachtForm from "./YachtForm";

const YachtAddPage = () => {

  const [redirect, setRedirect] = useState('');

  const onSaveButtonClicked = (yacht) => {
    console.log('new yacht', yacht);
    yachts.insert(yacht)
      .then(response => {
        console.log('new yacht inserted: ', response);
        setRedirect(<Navigate to="/yachts" />);
      })
      .catch(error => console.error(error));
  }

  return (
    <Fragment>
      {redirect}
      <YachtForm title="addNewYacht" onSaveButtonClicked={onSaveButtonClicked} />
    </Fragment>
  );
};

export default YachtAddPage;