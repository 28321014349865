import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { hasRole } from "../../core/Auth";
import YachtName from "./YachtName";

const YachtRow = (props) => {

  const yacht = props.yacht;

  return (
    <tr>
      <td>
        <YachtName yacht={yacht} createLink={true} />
      </td>
      <td>
        {yacht.type}
      </td>
      <td>
        {yacht.mmsi
          ? (
            <Link to={`/yachts-tracking/${yacht.id}`}>
              {yacht.mmsi}
            </Link>
            ) 
          : ''}
      </td>
      {hasRole('ADMIN') 
        ? (
        <td>
          <Link to={`/yachts/${yacht.id}/edit`} className="ui button" >
            <FormattedMessage id="action.edit" defaultMessage="Edit" />
          </Link>
        </td>
        ) : null}
    </tr>
  );
};

export default YachtRow;