import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import RankingListItem from "./RankingListItem";
import './RankingList.css';
import { Dimmer, Input, Loader, Segment, Checkbox } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import InfiniteScroll from 'react-infinite-scroll-component';
import RankingChart from "../common/ApexRankingChart";
import { hasRole } from "../../core/Auth";

const RANKING_DATA_CHANGED  = 'RANKING_DATA_CHANGED';
const CHECKED_ITEMS_CHANGED = 'CHECKED_ITEMS_CHANGED';
const COMPARE_DATA_CHANGED  = 'COMPARE_DATA_CHANGED';
const CHECKED_ITEMS_CLEAR   = 'CHECKED_ITEMS_CLEAR';

const contains = (oldItems, item) => {
  return oldItems.filter(it => it.person.id === item.person.id).length > 0;
}

const addItems = (oldItems, newItems) => {
  const itemsToAdd = [];
  newItems.forEach(item => {
    if (!contains(oldItems, item)) {
      itemsToAdd.push(item);
    } else {
      console.warn('Already got', item);
    }
  });

  return [...oldItems, ...itemsToAdd];
}

const reducer = (state, [type, payload]) => {

  const shouldClearItems = ({ meta }) => {
    return meta.type !== state.meta.type 
    || meta.param !== state.meta.param 
    || meta.query !== state.meta.query 
    || meta.captains !== state.meta.captains;
  }

  const newRankingPage = ({ranking, meta}) => {
    return {
      ...state,
      items: ranking.items || [],
      pageNumber: ranking.pageNumber || 1,
      totalItems: ranking.totalItems || 0,
      meta: meta || {type: '', param: '', query: '', captains: true},
      shouldDownloadMore: false
    };
  }

  const nextRankingPage = ({ranking, meta}) => {
    return {
      ...state,
      items: addItems(state.items, ranking.items),
      pageNumber: ranking.pageNumber, 
      totalItems: ranking.totalItems,
      meta: meta,
      shouldDownloadMore: false
    };
  }
  
  switch (type) {

    case RANKING_DATA_CHANGED:
      const ranking = payload;
      const meta = ranking.meta;
      console.log('RANKING_DATA_CHANGED', ranking, meta);
      if (shouldClearItems({meta})) {
        return newRankingPage({ranking, meta});
      }
      return nextRankingPage({ranking, meta});

    case CHECKED_ITEMS_CHANGED:
      const personId = payload;
      let checkedItems = state.checkedItems;
      if (checkedItems.indexOf(personId) >= 0) {
        checkedItems = checkedItems.filter(it => it !== personId);
      } else {
        checkedItems.push(personId);
      }
      console.log('checkedItems', checkedItems);
      return {...state, checkedItems: checkedItems};

    case CHECKED_ITEMS_CLEAR:
      console.log('CHECKED_ITEMS_CLEAR', payload);
      return {...state, checkedItems: [], ranksData: []};

    case COMPARE_DATA_CHANGED:
      console.log('COMPARE_DATA_CHANGED', payload);
      return {...state, ranksData: payload || []};

    default:
      return state;
  }
};

const initialState = {
  items: [],
  meta: {type: '', param: '', query: '', captains: true},
  pageNumber: 1,
  totalItems: 0,
  shouldDownloadMore: false,
  checkedItems: [],
  ranksData: []
};

const RankingList = ({ ranking, ranksData, search, onSearchInputChange, onPageChange, onCompareClick, onCaptainsClicked, loading }) => {

  const listSegmentRef = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [compareMode] = useState(false);

  useEffect(() => {    
    dispatch([RANKING_DATA_CHANGED, ranking]);
  }, [ranking]);

  useEffect(() => {    
    dispatch([COMPARE_DATA_CHANGED, ranksData]);
  }, [ranksData]);

  const fetchData = useCallback(() =>  {
    onPageChange({}, {activePage: state.pageNumber + 1});
  }, [onPageChange, state.pageNumber]);

  const hasMore = () => {
    return state.items.length < state.totalItems;
  }

  const getMinHeight = () => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const list_y0 = listSegmentRef?.current?.offsetTop || 0;
    return windowHeight - list_y0;
  };

  // const compare = () => {
  //   console.log('comparing', state.checkedItems);
  //   if (state.checkedItems.length > 0)
  //     onCompareClick(state.checkedItems);
  // };

  const onCompareSelect = personId => {
    dispatch([CHECKED_ITEMS_CHANGED, personId]);
  };

  // const clearCompared = () => {
  //   dispatch([CHECKED_ITEMS_CLEAR]);
  // }

  // const compareModeClicked = () => {
  //   if (compareMode) {
  //     clearCompared();
  //   }
  //   setCompareMode(!compareMode);
  // }

  return (
    <>
      <div className="ui segment">
        <FormattedMessage id="field.crewmember" defaultMessage="find a crewman">
          {msg => <Input
            placeholder={msg}
            className="ui inverted item"
            tabIndex="0"
            value={search}
            onChange={onSearchInputChange}
            icon="search"
            iconPosition="left"
          />}
        </FormattedMessage>

        {/* <div style={{float: 'right'}}>
          <FormattedMessage id="field.compareMode" defaultMessage="Compare mode">
            {msg => <Checkbox toggle onClick={compareModeClicked} checked={compareMode} label={msg} style={{marginRight: 1 + 'em'}} />}
          </FormattedMessage>

          <Button onClick={compare} disabled={state.checkedItems.length <= 1}>
            <FormattedMessage id="action.compare" defaultMessage="Compare" />
          </Button>
        </div> */}

        {hasRole('ADMIN') ? (
          <div style={{float: 'right'}}>
            <FormattedMessage id="field.with.captains" defaultMessage="with captains">
              {msg => <Checkbox toggle onClick={onCaptainsClicked} checked={ranking.meta.captains} label={msg} style={{marginRight: 1 + 'em'}} />}
            </FormattedMessage>
          </div>
        ) : null}
      </div> 

      {compareMode ? 
      <div className="ui segment">
        <RankingChart data={state.ranksData} />
      </div>
      : null}

      <div ref={listSegmentRef} style={{minHeight: getMinHeight() + 100 + 'px'}}>

        <Dimmer.Dimmable as={Segment} dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader>
              <FormattedMessage
                id="loading.ranking"
                defaultMessage="Loading ranking page"
              />
            </Loader>
          </Dimmer>

          <div id="milesrank-list-ranking">
            <InfiniteScroll      
              className="ui big relaxed middle aligned selection list"      
              style={{overflow: 'visible'}}
              dataLength={state.items.length}
              next={fetchData}
              hasMore={hasMore()}
              loader={<div className="ui small active centered inline loader"></div>}
            >
              {
                state.items.length > 0 
                ? state.items.map(item => <RankingListItem 
                  key={item.person.id} 
                  item={item} 
                  rankingType={state.meta.type} 
                  compareMode={compareMode} 
                  checked={state.checkedItems.indexOf(item.person.id) >= 0}
                  onCompareSelect={onCompareSelect}
                  />)
                : <div className="item" style={{minHeight: 3 + 'rem'}}>
                    {
                      loading
                      ? null
                      : <FormattedMessage id="empty.ranking" defaultMessage="No results found" />
                    }
                  </div>
              }
            </InfiniteScroll>
          </div>
        </Dimmer.Dimmable>

      </div>
    </>
  );

};

export default RankingList;