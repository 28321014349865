import React, { useEffect, useState } from 'react';
import VesselFinderMap from './VesselFinderMap';
import yachts from '../../apis/yachts';
import { useParams } from 'react-router';

const TrackingPage = () => {

  const { yachtId } = useParams();
  const [mmsi, setMmsi] = useState(227825030);

  useEffect(() => {
    yachts.get()
      .then(response => {
        const found = response.data.yachts.find(yacht => yacht.id === parseInt(yachtId));
        if (found === undefined) {
          console.warn("no yacht with id " + yachtId + " found");
          return null;
        }
        setMmsi(found.mmsi);
      })
      .catch(error => console.error(error));
  }, [yachtId]);

  return (
    <div>
      <VesselFinderMap
              params={{
                width: "100%", // width in pixels or percentage
                height: window.innerHeight - 100, // height in pixels
                // latitude: 0.0,// center latitude (decimal degrees)
                // longitude: 0.0, // center longitude (decimal degrees)
                zoom: 10, // initial zoom (between 3 and 18)
                names: true, // always show ship names (defaults to false),
                clickToActivate: true,
                storePosition: true,
                // fil: true, // ?
                // defaultMapype: undefined, // ?

                // single ship tracking
                mmsi: mmsi, // display latest position (by MMSI)
                // imo: "",         // display latest position (by IMO, overrides MMSI)
                showTrack: true, // display track line (last 24 hours)

                // fleet tracking
                // fleet: "e48ab3d80a0e2a9bf28930f2dd08800c", // your personal Fleet key (displayed in your User Profile)
                // fleetName: "Carnival",// display particular fleet from your fleet list
                // fleetTimespan: "1440",// maximum age in minutes of the displayed ship positions
                // fleetHideOldPositions: false
              }}
            />
    </div>);
}

export default TrackingPage;