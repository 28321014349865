import React from "react";

class NotFoundError extends React.Component {
  render() {
    return (
      <div className="ui placeholder center aligned segment red">
        <div className="ui header red">404</div>
        <div className="content">{this.props.message}</div>
      </div>
    );
  }
}

export default NotFoundError;
