import React from "react";

const LoadingError = ({ message }) => {
  
  return (
    <div className="ui placeholder segment red">
      <div className="ui icon header">
        <i className="exclamation circle icon red"></i>
        {message}
      </div>
    </div>
  );
}

export default LoadingError;
