import InMemoryStorage from './InMemoryStorage';
import Storage from './Storage';

const storage = new Storage(new InMemoryStorage());
const USER_KEY = 'user';

const saveUser = (user) => {
  storage.save(USER_KEY, user);
};

const getUser = () => {
  return storage.load(USER_KEY);
};

const clearUser = () => {
  storage.delete(USER_KEY);
};

const hasRole = (role) => {
  const user = getUser();
  if (!user) 
    return false;
  
  const roles = user['https://milesrank.swapps.pl/roles'];
  return roles.filter(r => r === role).length > 0;
  
};

export { hasRole, saveUser, getUser, clearUser };