import React, { Fragment, useEffect, useReducer, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import cruises from "../../apis/cruises";
import LoadingError from "../common/LoadingError";
import CruiseDeleteModal from "./CruiseDeleteModal";
import CruisesPagedTable from "./CruisesPagedTable";
import { hasRole } from "../../core/Auth";
import { useQuery } from "../../hooks/Query";
import './CruisesPagedPage.css';

const DEFAULT_SIZE = 10;
// const MAX_SIZE = 100;
const FIRST_PAGE = 1;

const PAGE_CONTROL_CLICKED = "PAGE_CONTROL_CLICKED";
const DEBOUNCED_QUERY_CHANGED = "DEBOUNCED_QUERY_CHANGED";
const CRUISE_DELETED = "CRUISE_DELETED";
const CRUISE_PUBLISHED = "CRUISE_PUBLISHED";
const CRUISE_UNPUBLISHED = "CRUISE_UNPUBLISHED";

const reducer = (state, [type, payload]) => {

  switch (type) {

    case PAGE_CONTROL_CLICKED:
      return {...state, pageNumber: payload};

    case DEBOUNCED_QUERY_CHANGED:
      let changes = {debouncedQuery: payload};
      if (state.lastQuery !== payload) {
        changes.pageNumber = FIRST_PAGE;
      }
      changes.lastQuery = payload;
      return {...state, ...changes};

    case CRUISE_PUBLISHED: 
    case CRUISE_UNPUBLISHED: 
    case CRUISE_DELETED: 
      return {...state, pageNumber: FIRST_PAGE, changesCount: state.changesCount + 1};

    default:
      return state;
  }
}

const initialState = {
  pageNumber: FIRST_PAGE,
  pageSize: DEFAULT_SIZE,
  debouncedQuery: '',
  lastQuery: '',
  changesCount: 0
};

const CruisesPagedPage = () => {

  const [state, dispatch] = useReducer(reducer, initialState);  
  const {debouncedQuery, query, setQuery} = useQuery('');

  const [pageLoading, setPageLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cruiseToDelete, setCruiseToDelete] = useState({});
  const [loadingError, setLoadingError] = useState(false);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');

  const [cruisesPage, setCruisesPage] = useState({
    pageNumber: FIRST_PAGE,
    totalPagesCount: 0,
    totalItems: 0,
    cruises: []
  });

  useEffect(() => {

    const onCruisesLoadError = (error) => {
      setLoadingError(true);
      setLoadingErrorMessage(
        <FormattedMessage
          id="error.loading.cruises"
          defaultMessage="Whoops! Something went wrong :("
        />
      );
    };

    const loadCruises = () => {
      
      console.log('loadCruises', {pageNumber: state.pageNumber, pageSize: state.pageSize, debouncedQuery: state.debouncedQuery, changesCount: state.changesCount});
      // console.log('loadCruises', state);

      setPageLoading(true);
      cruises.getCruisesPage(state.pageNumber, state.pageSize, state.debouncedQuery)
        .then(({data}) => {
          setCruisesPage(data);
        })
        .catch(onCruisesLoadError)
        .finally(() => {
          setPageLoading(false);
        });
    };

    loadCruises();

  }, [state.debouncedQuery, state.pageNumber, state.pageSize, state.changesCount]);
  
  useEffect(() => {
    dispatch([DEBOUNCED_QUERY_CHANGED, debouncedQuery]);
  }, [debouncedQuery])

  const onCruisePublishClick = cruiseId => {
    setPageLoading(true);
    cruises.publish(cruiseId)
      .then(() => dispatch([CRUISE_PUBLISHED, cruiseId]))
      .catch(error => console.log(error))
      .finally(() => setPageLoading(false));
  };

  const onCruiseUnpublishClick = cruiseId => {
    setPageLoading(true);
    cruises.unpublish(cruiseId)
      .then(() => dispatch([CRUISE_UNPUBLISHED, cruiseId]))
      .catch(error => console.log(error))
      .finally(() => setPageLoading(false));
  };

  const onCruiseDeleteClick = cruiseId => {
    setCruiseToDelete(cruisesPage.cruises.find(c => c.id === cruiseId));
    openDeleteModal();
  };

  const onCruiseDeleteConfirm = () => {
    setPageLoading(true);
    cruises.delete(cruiseToDelete.id)
      .then(() => dispatch([CRUISE_DELETED, cruiseToDelete.id]))
      .catch(error => console.log(error))
      .finally(() => {
        closeDeleteModal();
        setPageLoading(false);
      });    
  };
  
  const openDeleteModal = () => setDeleteModalOpen(true);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false); 
    setCruiseToDelete({});
  };

  const onPageChange = (event, pagination) => {
    dispatch([PAGE_CONTROL_CLICKED, pagination.activePage])
  };

  return (
    <div>
      <h1 className="ui header">
        <FormattedMessage id="title.cruises" defaultMessage="Cruises" />
      </h1>

      <Dimmer.Dimmable as={Segment} dimmed={pageLoading}>
        <Dimmer active={pageLoading} inverted>
          <Loader>
            <FormattedMessage
              id="loading.cruises"
              defaultMessage="Loading cruises"
            />
          </Loader>
        </Dimmer>

        {loadingError ? (
          <LoadingError message={loadingErrorMessage} />
        ) : (
          <Fragment>
            {hasRole('ADMIN') 
              ? <Link to={`/cruises/add`} className="ui primary right labeled button icon" style={{marginBottom: '1em'}}>
                  <i className="ui icon plus"></i>
                  <FormattedMessage id="action.addNewCruise" defaultMessage="Add new cruise" />
                </Link> 
              : ''
            }
            <CruisesPagedTable 
              cruisesPage={cruisesPage} 
              query={query}
              onPageChange={onPageChange}
              onSearchInputChange={(event, { value }) => setQuery(value)}
              onCruiseDeleteClick={onCruiseDeleteClick}
              onCruisePublishClick={onCruisePublishClick}
              onCruiseUnpublishClick={onCruiseUnpublishClick} />
          </Fragment>
        )}
      </Dimmer.Dimmable>

      <CruiseDeleteModal 
        cruise={cruiseToDelete} 
        modalOpened={deleteModalOpen}
        onCloseModal={closeDeleteModal}
        onOpenModal={openDeleteModal}
        onCruiseDeleteConfirm={onCruiseDeleteConfirm}
      />
    </div>
  );
}

export default CruisesPagedPage;
