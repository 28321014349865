import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { hasRole } from "../../core/Auth";
import "./UserRow.css"

const UserRow = ({ user, onUserDeleteClick, onSendCodeClick }) => {

  const navigate = useNavigate();

  const copyRegistrationCode = (registrationCode) => {
    const appUrl = window.location.protocol + "//" + window.location.host;
    navigator.clipboard.writeText(`${appUrl}/?t=${registrationCode}`);
  }

  return (
    <tr>
      <td>
        {user.gdprConsent ? null : <Icon name="heart" className="red" /> }
        <Link to={`/people/${user.id}`}>{user.firstName}</Link>
      </td>
      <td>
        <Link to={`/people/${user.id}`}>{user.lastName}</Link>
      </td>
      <td>
        {user.email}
      </td>
      <td>
        <Link to={`/people/${user.id}`}>{user.pointsAvailable}/{user.pointsEarned}</Link>
      </td>
      <td>
        {user.gdprConsent?<Icon name="check"/>:<FormattedMessage id="text.noConsent" defaultMessage="no consent"/>}
      </td>
      {hasRole('ADMIN')
        ? (
        <>
          <td>
            <Dropdown
              icon='ellipsis vertical'
              floating
              button
              className='icon'
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={e => navigate(`/users/${user.id}/edit`)}>
                  <Icon name="edit" />
                  <FormattedMessage id="action.edit" defaultMessage="Edit" />
                </Dropdown.Item>
                {user.registrationToken ? 
                  <>
                    <Dropdown.Item onClick={e => copyRegistrationCode(user.registrationToken)} disabled={!user.registrationToken} description="awsd">
                      <Icon name="copy" />
                      <FormattedMessage id="action.copyCode" defaultMessage="Copy registration code" />
                    </Dropdown.Item>
                    <Dropdown.Item onClick={e => onSendCodeClick(user.id)} disabled={!user.email || !user.registrationToken}>
                      <Icon name="send" />
                      <FormattedMessage id="action.sendCode" defaultMessage="Send registration code" />
                    </Dropdown.Item>
                  </> : null}
                <Dropdown.Divider />
                <Dropdown.Item className="ui red" onClick={e => onUserDeleteClick(user.id)} disabled={user.cruises > 0}>
                  <Icon name="delete" className="ui red" />
                  <FormattedMessage id="action.delete" defaultMessage="Delete" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </>
        ) : null}
    </tr>
  );

}

export default UserRow;
