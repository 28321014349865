import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useNavigate } from "react-router";
import GdprName from "../common/GdprName";
import RankingPositionChange from "./RankingPositionChange";

const RankingListItem = ({ item, rankingType, compareMode, checked, onCompareSelect }) => {

  const navigate = useNavigate();

  const onSelect = event => {
    navigate(`/people/${item.person.id}`);
  };

  const onItemClick = event => {
    event.stopPropagation();
    compareMode ? onCompareSelect(item.person.id) : onSelect(event);
  }

  return (
    <div className="item ranking-item" onClick={onItemClick} style={{position: 'relative'}}>
      <RankingPositionChange change={item.change} debut={rankingType === 'YEARLY' ? false : item.debut} />
      <div className="image position">
        <div className="header">
          <span className="ui black circular big label">{item.rank - 1}.</span>
        </div>
      </div>
      <div className="content">
        <div className="header">
          {compareMode ? 
          <div className="ui checkbox">
            <input type="checkbox" className="hidden" checked={checked} onChange={onCompareSelect} />
            <label></label>
          </div> : null}
          <GdprName person={item.person} />
        </div>
        <div className="extra">
          <p className="ui label">
            <strong><FormattedNumber value={item.miles} minimumFractionDigits={2} maximumFractionDigits={2} /></strong>
            {' '}
            <FormattedMessage
                id="text.plural.milesSailed"
                values={{ miles: item.miles }}
                defaultMessage="{miles, plural, one {mile sailed} other {miles sailed}}"
              />              
          </p>
        </div>
      </div>
    </div>
  );
};

export default RankingListItem;