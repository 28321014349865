import React from "react";
import { FormattedMessage } from "react-intl";
import { hasRole } from "../../core/Auth";
import YachtRow from "./YachtRow";

const YachtsTable = (props) => {

  const yachtRows = props.yachts
      .map(yacht => <YachtRow key={yacht.id} yacht={yacht} />);
  return (
    <table className="ui celled padded table" style={{ marginTop: 0 }}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="header.yachtName" defaultMessage="Yacht name" />
          </th>
          <th>
            <FormattedMessage id="header.yachtType" defaultMessage="Yacht type" />
          </th>
          <th>
            <FormattedMessage id="header.mmsi" defaultMessage="MMSI" />
          </th>
          {hasRole('ADMIN') ? <th></th> : null}
        </tr>
      </thead>
      <tbody>{yachtRows}</tbody>
    </table>
  );
}

export default YachtsTable;