import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { Dropdown, Icon } from "semantic-ui-react";
import { hasRole } from "../../core/Auth";
import YachtName from "../yachts/YachtName";
import GdprName from "../common/GdprName";

const CruiseSimpleRow = ({ cruise, onCruiseDeleteClick, onCruisePublishClick, onCruiseUnpublishClick }) => {

  const navigate = useNavigate();

  const renderApproximateDate = cruise => {
    return (
      <td colSpan={2}>
        <FormattedDate
          value={cruise.startDate}
          year="numeric"
          month="long" />
      </td>
    );
  }

  const renderFullDate = cruise => {
    return (
      <React.Fragment>
        <td>
          <FormattedDate
            value={cruise.startDate}
            year="numeric"
            month="long"
            day="numeric"
          />
        </td>
        <td>
          <FormattedDate
            value={cruise.endDate}
            year="numeric"
            month="long"
            day="numeric"
          />
        </td>
      </React.Fragment>
    );
  }

  const renderCruiseDates = cruise => {
    if (cruise.approximateDate)
      return renderApproximateDate(cruise)
    return renderFullDate(cruise)
  }

  const cruiseClassName = cruise => cruise.published ? null : "unpublished";

  return (
    <tr className={cruiseClassName(cruise)}>
      <td>
        <Link to={`/cruises/${cruise.id}`}>{cruise.name}</Link>
      </td>
      {renderCruiseDates(cruise)}
      <td>
        <YachtName yacht={cruise.yacht} createLink={true} />
      </td>
      <td>
        <Link to={`/people/${cruise.captain.id}`}>
          <GdprName person={cruise.captain} prefix={<FormattedMessage id="abbr.captain" defaultMessage="cpt." />} />
        </Link>
      </td>
      <td className="right aligned">
        <FormattedNumber value={cruise.miles} minimumFractionDigits={2} maximumFractionDigits={2} />
      </td>

      {hasRole('ADMIN')
        ? (
        <td>
          <Dropdown
            icon='ellipsis vertical'
            floating
            button
            className='icon'
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={e => navigate(`/cruises/${cruise.id}/edit`)}>
                <Icon name="edit" />
                <FormattedMessage id="action.edit" defaultMessage="Edit" />
              </Dropdown.Item>              

              {
                cruise.published ?                 
                <Dropdown.Item onClick={e => onCruiseUnpublishClick(cruise.id)}>
                  <Icon name="pause" className="red" />
                  <FormattedMessage id="action.unpublishCruise" defaultMessage="Unpublish cruise" />
                </Dropdown.Item> :  
                <Dropdown.Item onClick={e => onCruisePublishClick(cruise.id)}>
                  <Icon name="play" className="green" />
                  <FormattedMessage id="action.publishCruise" defaultMessage="Publish cruise" />
                </Dropdown.Item>                  
              }

              <Dropdown.Divider />

              <Dropdown.Item className="ui red" onClick={e => onCruiseDeleteClick(cruise.id)} disabled={cruise.published}>
                <Icon name="delete" className="ui red" />
                <FormattedMessage id="action.delete" defaultMessage="Delete" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      ) : null}


      {/* {hasRole('ADMIN') ? (
        <td>
          <button className="ui red button inverted" onClick={() => onCruiseDeleteClick(cruise.id)}>
            <FormattedMessage id="action.delete" defaultMessage="Delete" />
          </button>
          <Link className="ui button" to={`/cruises/${cruise.id}/edit`}>
            <FormattedMessage id="action.edit" defaultMessage="Edit" />
          </Link>
        </td>
      ) : null} */}
    </tr>
  );
}

export default CruiseSimpleRow;