import React from "react";
import { Icon } from "semantic-ui-react";
import { hasRole } from "../../core/Auth";

/**
 * 
 * @param {*} person 
 * @param {*} overrideName
 * @returns 
 */
const GdprName = ({ person, overrideName, prefix }) => {

  const isAdmin = hasRole('ADMIN');
  const gdprConsent = person.gdprConsent;
  const heart = <Icon name="heart" className="gdpr-icon red" />  
  let name = overrideName !== undefined ? overrideName : `${person.firstName} ${person.lastName}`

  const hasSpace = text => {
    return /\s/g.test(text);
  }

  if (!isAdmin && !gdprConsent) {
    if (hasSpace(name)) {
      return <span className="gdpr-filler">{prefix} {heart}{heart}{heart} {heart}{heart}{heart}{heart}</span>
    } else {
      return <span className="gdpr-filler">{prefix} {heart}{heart}{heart}</span>
    }
  }

  return gdprConsent ? <>{prefix} {name}</> : <>{heart} {prefix} {name}</>;
}

export default GdprName;