import { useCallback, useEffect, useState } from "react";

export const useQuery = (initialQuery, debounceMs = 500) => {

  const [query, setQuery] = useState(initialQuery);
  const [debouncedQuery, setDebouncedQuery] = useState(initialQuery);
  
  const getQuerySearchParam = useCallback((clear) => {    
    if (debouncedQuery && !clear) 
      return {query: encodeURIComponent(debouncedQuery)}
    return '';  
  }, [debouncedQuery]);

  useEffect(() => {
    const timeout = window.setTimeout(() => setDebouncedQuery(query), debounceMs);
    return () => window.clearTimeout(timeout);
  }, [query, debounceMs]);

  return {debouncedQuery, query, setQuery, getQuerySearchParam};

};