import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { hasRole } from "../../core/Auth";
import Statistics, { YACHTS } from "../common/Statistics";
import YachtName from "./YachtName";

const YachtCard = ({yacht, loading, stats}) => {

  return (
    <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader>
            <FormattedMessage
              id="loading.yacht.details"
              defaultMessage="Loading yacht details"
            />
          </Loader>
        </Dimmer>
        
        <h1 className="ui header icon">
          <YachtName yacht={yacht} />
        </h1>

        <div className="ui segment">
        <div className="content">
          <div className="meta">
            <span className="date">
              {yacht.type}
              {yacht.mmsi ? <Fragment><br /><span>MMSI: <Link to={`/yachts-tracking/${yacht.id}`}>{yacht.mmsi}</Link></span></Fragment> : null}
            </span>
          </div>

          <div className="ui divider"></div>

          <Statistics stats={stats} excludes={[YACHTS]} />
        </div>
      </div>

      <div className="ui segment">
        <Link to={`/?yacht=${yacht.id}`} className="ui blue labeled icon button">
          <i className="list ol yellow icon"></i>
          <FormattedMessage id="action.showYachtRanking" defaultMessage="Show ranking for {yacht}" values={{yacht: yacht.name}} />
        </Link>
        
        {
          hasRole('ADMIN') 
          ? (
            <Link className="ui button" to={`/yachts/${yacht.id}/edit`}>
              <FormattedMessage id="action.edit" defaultMessage="Edit" />
            </Link>
          ) 
          : ''}
      </div>
    </Dimmer.Dimmable>
  );
}

export default YachtCard;