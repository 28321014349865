import React, { Fragment } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import GdprName from "./GdprName";
import { hasRole } from "../../core/Auth";

const CruiseRow = ({ cruise }) => {

  const resolveCaptainLink = (captain) => {
    return (
      <div>
        <Link to={`/people/${cruise.captain.id}`}>
          <span>
            <GdprName person={captain} prefix={<FormattedMessage id="abbr.captain" defaultMessage="cpt." />} />
        </span>
        </Link>
      </div>
    );
  };

  const renderCruiseDates = (cruise) => {
    if (cruise.approximateDate)
      return renderApproximateDate(cruise)
    return renderFullDate(cruise)
  }

  const renderApproximateDate = cruise => {
    return (
      <td colSpan={2}>
        <FormattedDate
          value={cruise.startDate}
          year="numeric"
          month="long" />
      </td>
    );
  }

  const renderFullDate = cruise => {
    return (
      <Fragment>
        <td>
          <FormattedDate
            value={cruise.startDate}
            year="numeric"
            month="long"
            day="numeric"
          />
        </td>
        <td>
          <FormattedDate
            value={cruise.endDate}
            year="numeric"
            month="long"
            day="numeric"
          />
        </td>
      </Fragment>
    );
  }

  const cruiseClassName = cruise => cruise.published ? null : "unpublished";

  return (
    <tr className={cruiseClassName(cruise)}>
      <td>
        <Link to={`/cruises/${cruise.id}`}>{cruise.name}</Link>
      </td>
      {renderCruiseDates(cruise)}
      <td>
        {resolveCaptainLink(cruise.captain)}
      </td>
      <td className="right aligned">
        <FormattedNumber value={cruise.miles} minimumFractionDigits={2} maximumFractionDigits={2} />
        {cruise.extraMiles ? <span className="ui extra"> / <FormattedNumber value={cruise.extraMiles} minimumFractionDigits={2} maximumFractionDigits={2} /></span> : null}
      </td>
      {hasRole('ADMIN') ?
      <td className="right aligned">
        +{cruise.pointsEarned} / -{cruise.pointsSpent}
      </td>
      : null }
    </tr>
  );
}

export default CruiseRow;