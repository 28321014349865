import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

const CruiseDeleteModal = ({ cruise, modalOpened, onCloseModal, onOpenModal, onCruiseDeleteConfirm }) => {

  return (
    <Modal basic
      onClose={onCloseModal}
      onOpen={onOpenModal}
      open={modalOpened}
      size='small'
    >
      <Header icon>
        <Icon name='delete' />
          <FormattedMessage id="confirm.deleteCruise.title" defaultMessage="Are you sure you want to delete cruise {cruiseName}?" values={{cruiseName: cruise.name}} />
      </Header>
      <Modal.Content>
        <p>
          <FormattedMessage id="confirm.deleteCruise.text" defaultMessage="If you delete {cruiseName} there is no going back." values={{cruiseName: cruise.name}} />
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' inverted onClick={onCloseModal}>
          <Icon name='remove' /> <FormattedMessage id="action.cancelDelete" defaultMessage="No, cancel" />
        </Button>
        <Button color='red' inverted onClick={onCruiseDeleteConfirm}>
          <Icon name='checkmark' /> <FormattedMessage id="action.confirmDelete" defaultMessage="Yes, delete it" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CruiseDeleteModal;