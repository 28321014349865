import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Dropdown } from "semantic-ui-react";
import countries from "../../apis/countries.json";

const YachtForm = (props) => {

  const [yachtName, setYachtName] = useState("");
  const [yachtType, setYachtType] = useState("");
  const [mmsi, setMmsi] = useState("");
  const [nationality, setNationality] = useState("");

  const NO_FLAG_COUNTRIES = ['AQ','BQ','CW','GG','IM','JE','BL','MF','SX','SS'];
  const countryOptions = countries
    .filter(({ Code }) => NO_FLAG_COUNTRIES.indexOf(Code) < 0)
    .map(({ Code, Name }) => ({ key: Code.toLocaleLowerCase(), value: Code.toLocaleLowerCase(), flag: Code.toLocaleLowerCase(), text: Name }));

  useEffect(() => {
    if (props.yacht === undefined) {
      return;
    }
    const yacht = props.yacht;  
    setYachtName(yacht.name || '');
    setYachtType(yacht.type || '');
    setMmsi(yacht.mmsi || '');
    setNationality(yacht?.nationality?.toUpperCase() || '');
  }, [props.yacht]);

  const onSaveButtonClicked = () => {
    // validate
    props.onSaveButtonClicked({
      name: yachtName,
      type: yachtType,
      mmsi: mmsi,
      nationality: nationality
    });
  }

  return (
    <div className="ui component">
      <h1 className="ui header">
        <FormattedMessage
          id={`title.${props.title}`}
          defaultMessage="Add a new person"
        />
      </h1>
      <div className="ui segment">
        <form className="ui form" onSubmit={e => e.preventDefault()}>
          <div className="field">
            <label htmlFor="name"><FormattedMessage id="field.yachtName" defaultMessage="Yacht name" /></label>
            <input type="text" name="name" id="name" value={yachtName} onChange={e => setYachtName(e.target.value)} />
          </div>
          <div className="field">
            <label htmlFor="type"><FormattedMessage id="field.yachtType" defaultMessage="Yacht type" /></label>
            <input type="text" name="type" id="type" value={yachtType} onChange={e => setYachtType(e.target.value)} />
          </div>
          <div className="field">
            <label><FormattedMessage id="field.yachtNationality" defaultMessage="Yacht nationality" /></label>
            <FormattedMessage id="action.selectCountry" defaultMessage="Select country">
              {([msg]) => (              
                <Dropdown
                  clearable
                  fluid
                  selection
                  search
                  placeholder={msg}
                  value={nationality.toLocaleLowerCase()}
                  onChange={(e, { value }) => setNationality(value.toUpperCase())}
                  options={countryOptions}
                />
              )}
            </FormattedMessage>          
          </div>
          <div className="field">
            <label htmlFor="mmsi"><FormattedMessage id="field.mmsi" defaultMessage="MMSI" /></label>
            <input type="number" name="mmsi" id="mmsi" value={mmsi} onChange={e => setMmsi(e.target.value)} />
          </div>
          <div className="field">
            <button className="ui primary button" onClick={onSaveButtonClicked}><FormattedMessage id="action.save" defaultMessage="Save" /></button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default YachtForm;