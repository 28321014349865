import _ from "lodash";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Accordion, Dimmer, Icon, Loader } from "semantic-ui-react";
import { useMobileDetection } from "../../hooks/MobileDetecton";
import Statistic from "./Statistic";
import './Statistics.css';

export const YACHTS = 'YACHTS';
export const CRUISES = 'CRUISES';
export const CAPTAINS = 'CAPTAINS';
export const DAYS = 'DAYS';
export const FULL_DAYS = 'FULL_DAYS';
export const POINTS_EARNED = 'POINTS_EARNED';
export const POINTS_AVAILABLE = 'POINTS_AVAILABLE';
export const CREW = 'CREW';
export const PEOPLE = 'PEOPLE';
export const TOP_POSITION = 'TOP_POSITION';

const Statistics = ({ stats, includes, excludes, loading }) => {

  const isMobile = useMobileDetection()
  const [active, setActive] = useState(!isMobile);

  const handleClick = () => {
    setActive(!active)
  };

  const shouldShow = statName => {

    if (!_.isEmpty(includes))
      return includes.includes(statName);
    
    if (_.isEmpty(excludes))
      return true;

    return !excludes.includes(statName);
  }

  // console.log("Statistics", { stats, includes, excludes });

  // const loading = true;
  // const stats = {};
  return (
    <Dimmer.Dimmable dimmed={loading} className="milesrank-stats">
      <Dimmer active={loading} inverted>
        <Loader>
          <FormattedMessage
            id="loading.statistics"
            defaultMessage="Loading statistics"
          />
        </Loader>
      </Dimmer>
      <div id="milesrank-main-stats">
        <div className="ui statistics">
          <Statistic style={isMobile ? {width: 100 + '%'} : {}}
            value={stats.miles} 
            formatAsNumber messageId="field.plural.milesSailed" 
            messageValues={{ miles: stats.miles }} 
            defaultMessage="{miles, plural, one {Mile} other {Miles}}" />
        </div>
      </div>

      <Accordion>        
        {
          isMobile && !active
          ? <Accordion.Title
              active={active}
              index={0}
              onClick={handleClick}
              className="ui right aligned"
            >
              <Link to="." style={{display: 'block', width: 100 + '%', textAlign: 'center'}} onClick={e => e.preventDefault()}>
                <Icon name="angle down" /><FormattedMessage id="action.showMoreStatistics" defaultMessage="show more stats..." /><Icon name="angle down" />
              </Link>
            </Accordion.Title>
          : null
        }
        <Accordion.Content active={active}>

          <div id="milesrank-more-stats">
            
            <div className="ui statistics content">
              
              {shouldShow(POINTS_AVAILABLE) && shouldShow(POINTS_EARNED) 
                && typeof stats.pointsAvailable !== 'undefined'
                && typeof stats.pointsEarned !== 'undefined' ? 
              <Statistic 
                value={`${stats.pointsAvailable}/${stats.pointsEarned}`}
                messageId="field.plural.pointsAvailableOfEarned"
                messageValues={{ points: stats.pointsAvailable }} 
                defaultMessage="{points, plural, one {Point} other {Points}}" 
                formatAsNumber={false} /> : null}
                
              
              {shouldShow(CRUISES) ? 
              <Statistic 
                value={stats.cruises} 
                messageId="field.plural.cruisesSailed"
                messageValues={{ cruises: stats.cruises }} 
                defaultMessage="{cruises, plural, one {Cruise} other {Cruises}}" /> : null}

              {shouldShow(FULL_DAYS) ? 
              <Statistic 
                value={stats.fullDays} 
                messageId="field.plural.fullDaysSailed"
                messageValues={{ fullDays: stats.fullDays }} 
                defaultMessage="{fullDays, plural, one {Day} other {Days}}" /> : null}

              {shouldShow(CREW) ? 
              <Statistic 
                value={stats.crew} 
                messageId="field.plural.crewCount"
                messageValues={{ crewCount: stats.crew }} 
                defaultMessage="{crewCount, plural, one {Person} other {People}}" /> : null}

              {shouldShow(CAPTAINS) ? 
              <Statistic 
                value={stats.captains} 
                messageId="field.plural.captains"
                messageValues={{ captains: stats.captains }} 
                defaultMessage="{captains, plural, one {Captain} other {Captains}}" /> : null}

              {shouldShow(YACHTS) ? 
              <Statistic 
                value={stats.yachts} 
                messageId="field.plural.yachts"
                messageValues={{ yachts: stats.yachts }} 
                defaultMessage="{yachts, plural, one {Yacht} other {Yachts}}" /> : null}

              {shouldShow(PEOPLE) && stats.people ? 
              <Statistic 
                value={stats.people} 
                messageId="field.plural.peopleMet"
                messageValues={{ people: stats.people }} 
                defaultMessage="{people, plural, one {Person met} other {People met}}" /> : null}

              {shouldShow(TOP_POSITION) && stats.topPosition && stats.topPosition < 999999 ? 
              <Statistic 
                value={stats.topPosition} 
                messageId="field.topPosition"
                defaultMessage="Top position" /> : null}

            </div>
          </div>
        </Accordion.Content>
        {
          isMobile && active
            ? <Accordion.Title
                active={active}
                index={0}
                onClick={handleClick}
                className="ui right aligned"
              >
                <Link to="." style={{display: 'block', width: 100 + '%', textAlign: 'center'}} onClick={e => e.preventDefault()}>
                  <Icon name="angle up" /><FormattedMessage id="action.showLessStatistics" defaultMessage="show less stats..." /><Icon name="angle up" />
                </Link>
              </Accordion.Title>
            : null
        }

      </Accordion>
    </Dimmer.Dimmable>
  );
} 

export default Statistics;
