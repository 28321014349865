import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Button, Icon, Search, Dropdown } from "semantic-ui-react";
import fu from "../common/formUtils";
import CruiseCrewForm from "./CruiseCrewForm";
import "./CruiseAddPage.css";

const CruiseForm = ({
  cruise,
  yachts,
  errors,
  errorMessages,
  onCrewSearch,
  onCaptainSearch,
  onAddNewPerson,
  onSubmit,
}) => {
  const [cruiseName, setCruiseName] = useState("");
  const [description, setDescription] = useState("");
  const [yachtId, setYachtId] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [approximateDate, setApproximateDate] = useState(false);
  const [miles, setMiles] = useState("");
  const [fullDays, setFullDays] = useState("");
  const [pointsEarned, setPointsEarned] = useState("");
  const [captainId, setCaptainId] = useState("");
  const [crewmembers, setCrewmembers] = useState([]);

  const [captainLoading, setCaptainLoading] = useState(false);
  const [captainSearchResults, setCaptainSearchResults] = useState([]);
  const [captainSearchValue, setCaptainSearchValue] = useState("");

  const personMapper = (person) => {
    console.log("personMapper", person);
    return {
      id: person.id,
      title: person.firstName + " " + person.lastName,
      milles: person.miles ? person.miles : miles,
      fulldays: person.fullDays ? person.fullDays : fullDays,
      pointsearned: person.pointsEarned ? person.pointsEarned : pointsEarned,
      pointsspent: person.pointsSpent ? person.pointsSpent : 0,
      image: person.ranked === undefined ? "1" : person.ranked ? "1" : "",
    };
  };

  const calculateDays = (startDateMs, endDateMs) => {
    if (startDateMs === null || endDateMs === null) return 0;
    return Math.floor((endDateMs - startDateMs) / (1000 * 60 * 60 * 24));
  };

  const fillCruiseData = useCallback((cruise) => {
    setCruiseName(cruise.name);
    setDescription(cruise.description ? cruise.description : "");
    setYachtId(cruise.yachtId);
    setApproximateDate(cruise.approximateDate);
    setMiles(cruise.cruiseMiles);

    const startDateMs = Date.parse(cruise.startDate);
    const endDateMs = Date.parse(cruise.endDate);
    const fullDays = calculateDays(startDateMs, endDateMs);
    setStartDate(new Date(startDateMs));
    setEndDate(new Date(endDateMs));
    setFullDays(fullDays);
    setPointsEarned(fullDays);

    const captain = cruise.captain;
    setCaptainId(captain.id);
    setCaptainSearchValue(`${captain.firstName} ${captain.lastName}`);
    setCrewmembers(
      [captain, ...cruise.crew].map((cm) => ({
        id: cm.id,
        title: cm.firstName + " " + cm.lastName,
        milles: cm.miles,
        fulldays: cm.fullDays,
        pointsearned: cm.pointsEarned,
        pointsspent: cm.pointsSpent,
        image: cm.ranked ? "1" : "",
      }))
    );
  }, []);

  useEffect(() => {
    if (cruise) fillCruiseData(cruise);
  }, [cruise, fillCruiseData]);

  const onFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onSubmitClicked = () => {
    onSubmit({
      name: cruiseName,
      description: description,
      yachtId: yachtId,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      approximateDate: approximateDate,
      miles: miles,
      captainId: captainId,
      crew: crewmembers.map((c) => ({
        personId: c.id,
        miles: c.milles,
        fullDays: c.fulldays,
        pointsEarned: c.pointsearned,
        pointsSpent: c.pointsspent,
        ranked: !!c.image,
      })),
    });
  };

  const onDeleteCrewmember = (personId) => {
    if (personId === captainId) {
      setCaptainId(null);
      setCaptainSearchValue("");
    }
  };

  const onDateRangeChange = (event, data) => {
    if (data.value !== null) {
      const startDate = data.value[0];
      const endDate = data.value[1];
      const fullDays = calculateDays(startDate, endDate);
      setStartDate(startDate);
      setEndDate(endDate);
      setFullDays(fullDays);
      setPointsEarned(fullDays);
    } else {
      setStartDate(null);
      setEndDate(null);
      setFullDays(null);
      setPointsEarned(null);
    }
  };

  const getDates = () => {
    if (endDate || startDate) {
      return endDate === undefined ? [startDate] : [startDate, endDate];
    }
    return [new Date(), new Date()];
  };

  const onApproximateDateClick = () => {
    setApproximateDate(!approximateDate);
  };

  const onCaptainResultSelect = (event, data) => {
    console.log("onCaptainResultSelect", data.result);
    addCaptain(data.result);
  };

  const addCaptain = (captain) => {
    setCaptainSearchResults([]);
    setCaptainSearchValue(captain.title);
    setCaptainId(captain.id);
    setCrewmembers([captain, ...crewmembers]);
  };

  const onCaptainSearchChange = (event, { value }) => {
    setCaptainSearchValue(value);
    setCaptainLoading(true);
    onCaptainSearch(value).then((results) => {
      setCaptainId(null);
      setCaptainLoading(false);
      setCaptainSearchResults(
        results.filter(notAlreadyInCrew).map(personMapper)
      );
    });
  };

  const onAddCaptainPersonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const parsed = parsePerson(captainSearchValue);
    if (parsed === null) {
      console.warn("Cannot parse " + captainSearchValue);
      return null;
    }

    onAddNewPerson(parsed)
      .then((newPerson) => {
        if (newPerson !== undefined) addCaptain(personMapper(newPerson));
      })
      .catch((e) => console.error("caught", e));
  };

  const crewResultRenderer = ({ title }) => <div>{title}</div>;

  const notAlreadyInCrew = (crewMember) => {
    return crewmembers.find((a) => a.id === crewMember.id) === undefined;
  };

  const parsePerson = (fullName) => {
    const nameArr = fullName.trim().split(" ");
    return {
      lastName: nameArr.pop().trim(),
      firstName: nameArr.map((namePart) => namePart.trim()).join(" "),
    };
  };

  const anyErrors = () => {
    return Object.keys(errors).length > 0;
  };

  const numberValue = (value) => {
    return value.replace(/[^0-9,.]/i, "").replace(",", ".");
  };

  const formatDate = (date) => {
    if (date === null) return "";
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    return `${ye}-${mo}-${da}`;
  };

  const yachtOptions = yachts.map((y, i) => ({
    key: y.id,
    value: y.id,
    text: `${y.name} (${y.type})`,
  }));

  const submitButtonClassName = () => "ui button " + (!!cruise?.published ? "blue" : "green");

  return (
    <div className="ui segment">
      <div className="ui error message" hidden={!anyErrors()}>
        <div className="header">
          <FormattedMessage
            id="msg.errorsOccured"
            defaultMessage="There were some errors"
          />
        </div>
        <ul className="list">
          {errorMessages
            .flatMap((msg) => msg)
            .map((mmsg, i) => (
              <li key={i}>{mmsg}</li>
            ))}
        </ul>
      </div>

      <form
        className={fu.classForForm("ui form", anyErrors())}
        onSubmit={onFormSubmit}
        style={{ paddingBottom: 2 + "em", marginBottom: 2 + "em" }}
      >
        <div>
          <div className={fu.classForField("cruiseName", "field", errors)}>
            <label htmlFor="cruiseName">
              <FormattedMessage
                id="field.cruiseName"
                defaultMessage="Cruise name"
              />
            </label>
            {fu.fieldErrors("cruiseName", errors)}
            <input
              type="text"
              id="cruiseName"
              name="cruiseName"
              value={cruiseName}
              onChange={(e) => setCruiseName(e.target.value)}
            />
          </div>

          <div
            className={fu.classForField("cruiseDescription", "field", errors)}
          >
            <label htmlFor="description">
              <FormattedMessage
                id="field.cruiseDescription"
                defaultMessage="Short cruise description"
              />
            </label>
            {fu.fieldErrors("cruiseDescription", errors)}
            <textarea
              type="text"
              id="description"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            ></textarea>
          </div>

          <div className={fu.classForField("yachtId", "field", errors)}>
            <label htmlFor="yacht">
              <FormattedMessage id="field.yacht" defaultMessage="A yacht" />
            </label>
            {fu.fieldErrors("yacht", errors)}
            <FormattedMessage
              id="placeholder.yacht"
              defaultMessage="Choose yacht"
            >
              {([msg]) => (
                <Dropdown
                  clearable
                  fluid
                  selection
                  search
                  placeholder={msg}
                  value={yachtId}
                  onChange={(e, { value }) => setYachtId(value)}
                  options={yachtOptions}
                />
              )}
            </FormattedMessage>
          </div>

          <div
            className={fu.classForFields(
              ["startDate", "endDate"],
              "field",
              errors
            )}
          >
            {fu.fieldErrors("startDate", errors)}
            {fu.fieldErrors("endDate", errors)}
            <SemanticDatepicker
              className="milesrank-datepicker"
              locale="pl-PL"
              type="range"
              onChange={onDateRangeChange}
              value={getDates()}
              label={
                <FormattedMessage
                  id="field.cruiseDateRange"
                  defaultMessage="Cruise dates"
                />
              }
            />
            <span>&nbsp;&nbsp;&nbsp;pełne doby: {fullDays}</span>
          </div>

          <div className="field">
            <div className="ui checkbox" onClick={onApproximateDateClick}>
              <input
                type="checkbox"
                className="hidden"
                checked={approximateDate}
                onChange={onApproximateDateClick}
              />
              <label>
                <FormattedMessage
                  id="field.approximateDate"
                  defaultMessage="Approximate date"
                />
              </label>
            </div>
          </div>

          <div className={fu.classForField("cruiseMiles", "field", errors)}>
            <label htmlFor="miles">
              <FormattedMessage
                id="field.cruiseMileage"
                defaultMessage="Cruise mileage"
              />
            </label>
            {fu.fieldErrors("cruiseMiles", errors)}
            <input
              type="text"
              id="miles"
              name="miles"
              value={miles}
              onChange={(e) => setMiles(numberValue(e.target.value))}
            />
          </div>

          <div className={fu.classForField("captainId", "field", errors)}>
            <label htmlFor="captain">
              <FormattedMessage id="field.captain" defaultMessage="Captain" />
            </label>
            {fu.fieldErrors("captainId", errors)}
            <Search
              loading={captainLoading}
              onResultSelect={onCaptainResultSelect}
              onSearchChange={onCaptainSearchChange}
              results={captainSearchResults}
              value={captainSearchValue}
              resultRenderer={crewResultRenderer}
              minCharacters={3}
              noResultsMessage={
                <Button
                  primary
                  icon
                  labelPosition="left"
                  onClick={onAddCaptainPersonClick}
                >
                  <Icon name="plus" />
                  <FormattedMessage
                    id="action.addNewPerson"
                    defaultMessage="Add new person"
                  />
                </Button>
              }
            />
          </div>
        </div>

        <CruiseCrewForm
          crewmembers={crewmembers}
          setCrewmembers={setCrewmembers}
          errors={errors}
          errorMessages={errorMessages}
          onCrewSearch={onCrewSearch}
          onAddCrewPerson={onAddNewPerson}
          onDeleteCrewmember={onDeleteCrewmember}
          personMapper={personMapper}
          canAddToCrew={notAlreadyInCrew}
          parsePerson={parsePerson}
          isCaptain={(personId) => personId === captainId}
        />

        <br />

        <div className="field">
          <button className={submitButtonClassName()} onClick={onSubmitClicked}>
            <FormattedMessage
              id="action.saveCruise"
              defaultMessage="Save cruise"
            />
            &nbsp;({!!cruise?.published ? (
            <FormattedMessage
              id="text.published"
              defaultMessage="published"
            />
            ) : (
            <FormattedMessage
              id="text.not.published"
              defaultMessage="not published"
            />
            )})
          </button>
        </div>
      </form>
    </div>
  );
};

export default CruiseForm;
