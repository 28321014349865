import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import LoadingError from "../common/LoadingError";
import YachtsTable from "./YachtsTable";
import yachts from "../../apis/yachts";
import { hasRole } from "../../core/Auth";
import { Link } from "react-router-dom";

const YachtsPage = () => {

  const [yachtList, setYachtList] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState("");

  const loadYachts = () => {
    setPageLoading(true);
    yachts.get()
      .then(({ data }) => setYachtList(data.yachts))
      .catch(error => {
        setLoadingError(true);
        setLoadingErrorMessage(<FormattedMessage
          id="error.loading.ranking"
          defaultMessage="Whoops! Something went wrong :("
        />);
      })
      .finally(() => setPageLoading(false));
  }

  useEffect(() => {
    loadYachts()
  }, []);

  return (
    <Fragment>
      <h1 className="ui header">
        <FormattedMessage id="title.yachts" defaultMessage="Yachts" />
      </h1>

      <Dimmer.Dimmable as={Segment} dimmed={pageLoading}>
        <Dimmer active={pageLoading} inverted>
          <Loader>
            <FormattedMessage
              id="loading.yachts"
              defaultMessage="Loading yachts"
            />
          </Loader>
        </Dimmer>

        {loadingError ? (
          <LoadingError message={loadingErrorMessage} />
        ) : (
          <Fragment>
            {hasRole('ADMIN') 
              ? <Link to={`/yachts/add`} className="ui primary right labeled button icon" style={{marginBottom: '1em'}}>
                  <i className="ui icon plus"></i>
                  <FormattedMessage id="action.addNewYacht" defaultMessage="Add new yacht" />
                </Link> 
              : ''
            }
            <YachtsTable yachts={yachtList} />
          </Fragment>
        )}
      </Dimmer.Dimmable>
    </Fragment>
  );

};

export default YachtsPage;