import React, { useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import pl from 'apexcharts/dist/locales/pl.json';
import en from 'apexcharts/dist/locales/en.json';
import _ from 'lodash';

const RankingChart = ({ data, takeLast }) => {

  const [ options, setOptions ] = useState({})
  const [ series, setSeries ] = useState([])

  const distinctCruises = data => {
    console.log('data', data)
    const cruises = data
      .flatMap(({cruiseRanks}) => cruiseRanks.map(rank => ({id: rank.cruiseId, name: rank.cruiseName, date: Date.parse(rank.cruiseEndDate)})))
      .sort((c1, c2) => c1.date - c2.date)
    return _.sortedUniqBy(cruises, ({id}) => id);
  }

  const spreadAcrossCruises = (rawSeries, cruises) => {
    const processedSeries = [];
    for (let ser of rawSeries) {

      const newData = [];
      for (let cruise of cruises) {
        let lastFound = null;
        const found = _.find(ser.data, {id: cruise.id});
        if (found) {
          newData.push(found);
          lastFound = found;
        } else {
          newData.push(lastFound);
        }
      }
      
      processedSeries.push({
        name: ser.name,
        data: newData
      });
    }

    return processedSeries;
  }

  const createData = (data, takeLast) => {

    const cruises = distinctCruises(data);
    // console.log('all cruises', cruises);

    const rawSeries = data
      .map(({person, cruiseRanks}) => ({
        name: `${person.firstName} ${person.lastName}`,
        data: cruiseRanks.map(rank => ({id: rank.cruiseId, cruiseEndDate: rank.cruiseEndDate, position: rank.position, miles: rank.miles}))
      }));
    // console.log('rawSeries', rawSeries);

    const processedSeries = spreadAcrossCruises(rawSeries, cruises);
    // console.log('processedSeries', processedSeries);

    return {
      cruises: takeLast > 0 ? _.takeRight(cruises, takeLast) : cruises, 
      series: takeLast > 0 ? processedSeries.map(({name, data}) => ({name: name, data: _.takeRight(data, takeLast)})) : processedSeries
    };
  }

  const createDataCb = useCallback(createData, []);

  const monthName = value => {
    return pl.options.shortMonths[value];
  }

  const formatDateCb = useCallback(date => monthName(date.getMonth()) + " " + date.getFullYear(), []);

  useEffect(() => {

    if (_.isEmpty(data))
      return;

    // console.log(data);

    const {cruises, series} = createDataCb(data, takeLast ? takeLast : 0);
    console.log('cruises', cruises);
    console.log('series', series);

    // const timeSeries = series.map(s => ({name: s.name, data: _.takeRight(s.data.map(d => d ? [Math.ceil(Date.parse(d.cruiseEndDate)), d.position] : []), 10)}));
    // console.log('time series', timeSeries);

    const shouldShowLabels = cruises.length <= 100;

    setSeries(series.map(s => ({name: s.name, data: s.data.map(d => d !== null ? d.position - 1 : null)})));

    setOptions({
      chart: {
        locales: [pl, en],
        defaultLocale: 'pl',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      theme: {
        mode: 'light', 
        palette: 'palette1'
      },
      animations: {
        initialAnimation: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: shouldShowLabels
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      xaxis: {
        categories: cruises,//.map(({name}) => name),
        type: 'categories',
        tickPlacement: 'on',
        tickAmount: 6,
        tooltip: {
          enabled: true
        },
        labels: {
          showDuplicates: false,
          hideOverlappingLabels: true,
          formatter: cruise => cruise ? `${cruise.name} (${formatDateCb(new Date(cruise.date))})` : ''
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: true,
        shared: true
      },
      yaxis: {
        reversed: true,
        min: 0
      }
    });

  }, [data, takeLast, createDataCb, formatDateCb]);

  return (
    <div id="wrapper">
      <div id="chart-line2">
        <Chart options={options} series={series} type="line" height={400} />
      </div>
    </div>
  );
};

export default RankingChart;