class InMemoryStorage {

  _storage = {};

  setItem = (key, value) => {
    this._storage[key] = value;
  }
  
  getItem = key => {
    return this._storage[key];
  }

  removeItem = key => {
    return this.setItem(key, null);
  }
}

export default InMemoryStorage;