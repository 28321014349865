
const FormUtils = {

  classForForm: (baseClass, anyErrors) => {
    return `${baseClass}${anyErrors ? ' error' : ''}`;
  },

  classForField: (fieldName, baseClass, errors) => {
    return `${baseClass}${errors[fieldName] ? ' error' : ''}`;
  },

  classForFields: (fieldNames, baseClass, errors) => {
    const isValid = fieldNames
      .map(fieldName => errors[fieldName] === undefined)
      .reduce((a, b) => (a && b), true);

    return `${baseClass}${!isValid ? ' error' : ''}`;
  },

  fieldErrors: (fieldName, errors) => {
    if (errors[fieldName] === undefined)
      return null;
    const fieldErrors = errors[fieldName];
    return (
      <div className="ui error message">
        <ul className="list">
          {fieldErrors.map((msg, i) => <li key={i}>{msg}</li>)}
        </ul>
      </div>
    );  
  }

};

export default FormUtils;