import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import { Loader } from "semantic-ui-react";
import cruises from "../../apis/cruises";
import CruiseCard from "./CruiseCard";
import CrewList from "./CrewList";
import NotFoundError from "../common/NotFoundError";
import LoadingError from "../common/LoadingError";

const CruiseDetailsPage = () => {

  const { cruiseId } = useParams();
  const navigate = useNavigate();
  const [cruise, setCruise] = useState({
    name: "",
    startDate: "2021-07-01",
    endDate: "2021-07-02",
    crew: [],
    captain: {},
    cruiseMiles: 0,
    yacht: {}
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  const onCruiseLoadError = useCallback((error) => {
    let errorMessage = "";
    if (error.response && error.response.status === 404) {
      errorMessage = (
        <NotFoundError
          message={
            <FormattedMessage
              id="error.notFound.cruise"
              defaultMessage={`Cannot find cruise ${cruiseId}`}
            />
          }
        />
      );
    } else {
      errorMessage = (
        <LoadingError
          message={
            <FormattedMessage
              id="error.loading.cruise"
              defaultMessage="Whoops! Something went wrong :("
            />
          }
        />
      );
    }
    setError(true);
    setErrorMessage(errorMessage);
  }, [cruiseId]);

  useEffect(() => {
    setPageLoading(true);
    cruises
      .getById(cruiseId)
      .then(({data}) => setCruise(data))
      .catch(onCruiseLoadError)
      .finally(() => setPageLoading(false));
  }, [cruiseId, onCruiseLoadError]);

  const onRankingButtonClick = () => {
    navigate(`/?cruise=${cruise.id}`);
  };

  return (
    <div>
      <Loader active={pageLoading}>
        <FormattedMessage
          id="loading.cruise"
          defaultMessage="Loading cruise"
        />
      </Loader>
      {error ? (
        errorMessage
      ) : (
        <div hidden={pageLoading}>
          <CruiseCard
            cruise={cruise}
            onRankingButtonClick={onRankingButtonClick}
          />
          <CrewList captain={cruise.captain} crew={cruise.crew} />
        </div>
      )}

      <div hidden={!cruise.description} className="ui piled segment">{cruise.description}</div>

    </div>
  );
}

export default CruiseDetailsPage;
