import React from "react";
import { FormattedMessage } from "react-intl";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import CruiseRow from "../common/CruiseRow";
import { hasRole } from "../../core/Auth";

class PersonCruises extends React.Component {
  render() {
    let cruiseRows = this.props.cruises.map((cruise) => (
      <CruiseRow
        key={cruise.id}
        cruise={{...cruise, extraMiles: cruise.cruiseMiles, miles: cruise.personMiles}}
        personId={this.props.personId}
      />
    ));
    return (
      <Dimmer.Dimmable as={Segment} dimmed={this.props.loading}>
        <Dimmer active={this.props.loading} inverted>
          <Loader>
            <FormattedMessage
              id="loading.person.cruises"
              defaultMessage="Loading person cruises"
            />
          </Loader>
        </Dimmer>
        <table className="ui celled padded table" style={{ marginTop: 0 }}>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id="header.cruiseName"
                  defaultMessage="Cruise name"
                />
              </th>
              <th>
                <FormattedMessage
                  id="header.start"
                  defaultMessage="Cruise name"
                />
              </th>
              <th>
                <FormattedMessage
                  id="header.end"
                  defaultMessage="Cruise name"
                />
              </th>
              <th>
                <FormattedMessage
                  id="header.captain"
                  defaultMessage="Captain"
                />
              </th>
              <th className="right aligned">
                <FormattedMessage id="header.miles" defaultMessage="Miles" />
              </th>
              {hasRole('ADMIN') ?
              <th className="right aligned">
                <FormattedMessage id="header.points" defaultMessage="Points" />
              </th>
              : null}
            </tr>
          </thead>
          <tbody>{cruiseRows}</tbody>
        </table>
      </Dimmer.Dimmable>
    );
  }
}

export default PersonCruises;
