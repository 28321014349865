import React, { useState, useRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ReCAPTCHA from "react-google-recaptcha";
import qs from 'qs';
import { FormattedMessage } from "react-intl";
import { useMobileDetection } from "../../hooks/MobileDetecton";

const WelcomePage = (props) => {

  const { loginWithRedirect } = useAuth0();
  const isMobile = useMobileDetection();
  const recaptchaRef = useRef(null);
  const [token, setToken] = useState("");
  
  const onTokenEntered = () => {
    props.onTokenEntered(token, recaptchaRef);
  }

  useEffect(() => {
    const queryToken = qs.parse(window.location.search, { ignoreQueryPrefix: true }).t
    if (queryToken) {
      setToken(queryToken);
    }
  }, []);

  return (

    <div className="ui placeholder segment">
      <div className="ui two column stackable center aligned grid">
      {isMobile ? null : <div className="ui vertical divider"><FormattedMessage id="title.or" defaultMessage="or" /></div>}
        <div className="middle aligned row">
          <div className="column">
            <div className="ui header">
              <strong style={{display:"block", fontSize: "1.5em", lineHeight: "2em"}}>🤔</strong>
              <FormattedMessage id="title.whats.that.code" defaultMessage="I'm new here, but I have some kind of a code..." />
            </div>
            <div className="field">
                <div className={`ui action input ${props.error ? 'error' : ''}`} style={{left: '-3em'}}>
                  <FormattedMessage id="placeholder.enterCode" defaultMessage="Enter code">
                    {ph => <input type="text" placeholder={ph} value={token} onChange={e => setToken(e.target.value)} />}
                  </FormattedMessage>
                  <button className={`ui primary button` + (props.loading ? ` loading` : ``)} disabled={props.loading} onClick={onTokenEntered}>
                    <FormattedMessage id="action.submitCode" defaultMessage="Check it" />
                  </button>
                </div>
                <div className="ui message error" hidden={!props.error}>
                  <FormattedMessage id="error.token.invalid" defaultMessage="Code is invalid (or there is some problem with application)" />
                </div>
            </div>
            <div>
              <ReCAPTCHA 
                ref={recaptchaRef}
                sitekey={props.reCaptchaSiteKey} 
                size="invisible" 
                grecaptcha={window.grecaptcha}/>
            </div>
          </div>
          {isMobile ? <div className="ui horizontal divider"><FormattedMessage id="title.or" defaultMessage="or" /></div> : null}
          <div className="column">
            <div className="ui header">
              <strong style={{display:"block", fontSize: "1.5em", lineHeight: "2em"}}>😎</strong>
              <FormattedMessage id="title.been.here" defaultMessage="OK, I've been here before!" />
            </div>
            <div className={`ui secondary button` + (props.loading ? ` loading` : ``)} disabled={props.loading} onClick={() => loginWithRedirect()}>
              <FormattedMessage id="action.signIn" defaultMessage="Sign in" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomePage;