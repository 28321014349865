class Storage {

  storage = null;

  constructor(storage) {
    this.storage = storage;
  }

  save = (key, value) => {    
    // console.log('saving', key, value)
    this.storage.setItem(key, value);
  }

  load = key => {
    // console.log('loading', key)
    return this.storage.getItem(key);
  }

  delete = key => {
    this.storage.removeItem(key);
  }

}

export default Storage;