import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import GdprName from "../common/GdprName";

class CrewList extends React.Component {
  render() {
    let crewRows = this.props.crew.map((person) => (
      <tr key={person.id}>
        <td>
          <Link to={`/people/${person.id}`}>
            <GdprName person={person} />
          </Link>
        </td>
      </tr>
    ));
    let captain = this.props.captain;
    return (
      <table className="ui celled padded table">
        <thead>
          <tr>
            <th>
              <Link to={`/people/${captain.id}`}>
                <GdprName person={captain} prefix={<FormattedMessage id="abbr.captain" defaultMessage="cpt." />} />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>{crewRows}</tbody>
      </table>
    );
  }
}

export default CrewList;
