import React, { useEffect, useState, useCallback, Fragment } from "react";
import { Navigate, useParams } from "react-router";
import yachts from "../../apis/yachts";
import YachtForm from "./YachtForm";

const YachtEditPage = () => {

  const { yachtId } = useParams();
  const [yacht, setYacht] = useState({});
  const [redirect, setRedirect] = useState('');

  const loadYacht = (yachtId) => {
    yachts.getById(yachtId)
      .then(({data}) => {
        setYacht({...data});
      })
      .catch(error => console.log(error));
  }

  const onSaveButtonClicked = (yacht) => {
    console.log('edited yacht', yacht);
    yachts.update(yacht, yachtId)
      .then(response => {
        console.log('yacht updated: ', response);
        setRedirect(<Navigate to="/yachts" />);
      })
      .catch(error => console.error(error));
  }

  const loadYachtCb = useCallback(loadYacht, []);

  useEffect(() => {
    loadYachtCb(yachtId);
  }, [loadYachtCb, yachtId]);


  return (
    <Fragment>
      {redirect}
      <YachtForm yacht={yacht} title="editYacht" onSaveButtonClicked={onSaveButtonClicked} />
    </Fragment>
  );
};

export default YachtEditPage;