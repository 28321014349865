import milesrank from './milesrank';

const YachtsMethods = {

  get: () => {
    return milesrank.get("/api/yachts");
  },

  getById: (yachtId) => {
    return milesrank.get("/api/yachts/" + yachtId);
  },

  getStats: (yachtId) => {
    return milesrank.get("/api/yachts/" + yachtId + "/stats");
  },

  getYachtCruises: (yachtId) => {
    return milesrank.get("/api/yachts/" + yachtId + "/cruises");
  },

  insert: (yacht) => {
    return milesrank.post("/api/yachts", yacht, {
      // validateStatus: (status) => status < 500
    });
  },

  update: (yacht, yachtId) => {
    return milesrank.put("/api/yachts/" + yachtId, yacht, {
      // validateStatus: (status) => status < 500      
    });
  }

}

export default YachtsMethods;