import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RankingPage from "../ranking/RankingPage";
import PersonDetailsPage from "../people/PersonDetailsPage";
import CruiseDetailsPage from "../cruises/CruiseDetailsPage";
import CruiseAddPage from "../cruises/CruiseAddPage";
import CruiseEditPage from "../cruises/CruiseEditPage";
import CruisesPagedPage from "../cruises/CruisesPagedPage";
import YachtsPage from "../yachts/YachtsPage";
import TrackingPage from "../yachts/TrackingPage";
import YachtDetailsPage from "../yachts/YachtDetailsPage";
import YachtEditPage from "../yachts/YachtEditPage";
import YachtAddPage from "../yachts/YachtAddPage";
import UsersPage from "../users/UsersPage";
import UserEditPage from "../users/UserEditPage";
import UserAddPage from "../users/UserAddPage";

const AppRoutes = () => {

  return (
    <Routes>
      <Route end path="/" element={<RankingPage />} />
      <Route end path="/cruises/add" element={<CruiseAddPage />} />         
      <Route end path="/cruises" element={<CruisesPagedPage />} />
      <Route path="/cruises/:cruiseId/edit" element={<CruiseEditPage />} />
      <Route path="/cruises/:cruiseId" element={<CruiseDetailsPage />} />
      <Route end path="/users" element={<UsersPage />} />  
      <Route end path="/users/add" element={<UserAddPage />} />
      <Route path="/users/:userId/edit" element={<UserEditPage />} />
      <Route path="/people/:personId" element={<PersonDetailsPage />} />
      <Route end path="/yachts" element={<YachtsPage />} />
      <Route end path="/yachts/add" element={<YachtAddPage />} />
      <Route path="/yachts/:yachtId/edit" element={<YachtEditPage />} />
      <Route path="/yachts/:yachtId" element={<YachtDetailsPage />} />
      <Route path="/yachts-tracking/:yachtId" element={<TrackingPage />} />
    </Routes>
  );
}

export default AppRoutes;