import milesrank from "./milesrank";

const PeopleMethods = {

  get: (id) => {
    return milesrank.get("/api/people/" + id + "/details");
  },

  getStats: (id) => {
    return milesrank.get("/api/people/" + id + "/stats");
  },

  find: (query) => {
    return milesrank.get("/api/people/find", {
      params: {
        q: query,
      },
    });
  },

  save: (person) => {
    return milesrank.post("/api/people", person, {
      validateStatus: (status) => status < 500
    });
  },
}

export default PeopleMethods;