import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { hasRole } from "../../core/Auth";
import GdprName from "../common/GdprName";
import Statistics, { CREW } from "../common/Statistics";

const PersonCard = ({ person, stats, firstYear, loading }) => {

  return (
    <Dimmer.Dimmable dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader>
          <FormattedMessage
            id="loading.person.details"
            defaultMessage="Loading person details"
          />
        </Loader>
      </Dimmer>
        <h1 className="ui header">
          <GdprName person={person} />
        </h1>
        <div className="ui segment">
          <div className="meta content">
            <span className="date" hidden={firstYear === 99999}>
              <FormattedMessage
                id="field.joinedIn"
                defaultMessage="Joined in"
              />{" "}
              {firstYear}
            </span>
          </div>

          <div className="ui divider"></div>

          <div className="extra content">
            <Statistics stats={{...stats, people: stats.crew}} excludes={[CREW]} />
          </div>

        </div>

        {hasRole('ADMIN') 
          ? (
            <div className="ui segment">
              <Link className="ui button" to={`/users/${person.id}/edit`}>
                <FormattedMessage id="action.edit" defaultMessage="Edit" />
              </Link>
            </div>
          ) 
          : null}
        
    </Dimmer.Dimmable>
  );
}

export default PersonCard;
