import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import users from "../../apis/users";
import UserForm from "./UserForm";
import apiUtils from "../../apis/utils";

const UserAddPage = () => {

  const [errors, setErrors] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [user] = useState({});
  const navigate = useNavigate();

  const onSaveButtonClicked = (user) => {
    users.create(user)
      .then(apiUtils.onResponse(
        data => navigate(`/users`),
        data => handleValidationErrors(data),
        data => handleClientError(data)
      ))
      .catch(apiUtils.onError(handleError));
  }

  const handleValidationErrors = (data) => {
    console.log("validation errors response", data);
    let errors = [];
    for (let key in data.errors)
      if (data.errors.hasOwnProperty(key)) errors.push(data.errors[key]);
    console.log("validation errors", errors);
    setErrors(data.errors);
  };

  const handleClientError = (data) => {
    console.log("handleClientError", data);
    setErrors({ global: data.message });
    setErrorMessages([data.message]);
  };

  const handleError = error => {
    let message = "Oho! Nieoczekiwany błąd :(";
    setErrors({ global: message });
    setErrorMessages([message]);
  };


  return (
    <UserForm user={user} errors={errors} errorMessages={errorMessages} title="addPerson" onSave={onSaveButtonClicked} />
  );
};

export default UserAddPage;