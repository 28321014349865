
const Utils = {

  onCreateResponse: (onEntityCreated, onValidationErrors, onClientError) => {
    return response => {
      if (
        response.status === 422 &&
        response.data.message &&
        response.data.errors && 
        response.data.errorCode === "VALIDATION_ERROR") {
          onValidationErrors(response.data);
      } else if (response.status >= 400) {
        return onClientError(response.data)
      } else if (response.status === 201 && response.headers.location) {
        return onEntityCreated(response.headers.location.split("/").pop())      
      }
    }
  },

  onResponse: (onSuccess, onValidationErrors, onClientError) => {
    return response => {
      if (
        response.status === 422 &&
        response.data.message &&
        response.data.errors && 
        response.data.errorCode === "VALIDATION_ERROR") {
          onValidationErrors(response.data);
      } else if (response.status >= 400) {
        onClientError(response.data);
      } else {
        onSuccess(response.data); 
      }
    }
  },
  
  onError: (onError) => {
    return error => {
      if (error.response) {
        console.log('The request was made and the server responded with a status code that falls out of the range of 2xx');
        console.log('data', error.response.data);
        console.log('status', error.response.status);
        console.log('headers', error.response.headers);
      } else if (error.request) {
        console.log('The request was made but no response was received', error.request);
      } else {
        console.log("Something happened in setting up the request that triggered an Error", error.message);
      }
  
      if (error.toJSON)
        console.log(error.toJSON());
  
      if (onError !== undefined)
        onError(error);
    }
  }  

}

export default Utils;