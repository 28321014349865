import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import { Link } from "react-router-dom";
import { hasRole } from "../../core/Auth";
import le from "../../apis/letter-emojis.json";
import Statistics, { CREW, FULL_DAYS } from "../common/Statistics";

class CruiseCard extends React.Component {
  state = {
    redirect: "",
  };

  renderCruiseDates = (cruise) => {
    if (cruise.approximateDate) return this.renderApproximateDate(cruise);
    return this.renderFullDate(cruise);
  };

  renderApproximateDate = (cruise) => {
    return (
      <FormattedDate value={cruise.startDate} year="numeric" month="long" />
    );
  };

  renderFullDate = (cruise) => {
    return (
      <React.Fragment>
        <FormattedDate
          value={cruise.startDate}
          year="numeric"
          month="long"
          day="numeric"
        />
        &nbsp;-&nbsp;
        <FormattedDate
          value={cruise.endDate}
          year="numeric"
          month="long"
          day="numeric"
        />
      </React.Fragment>
    );
  };

  emojiFlag = (nationality) => {
    if (nationality) {
      const code = nationality.toUpperCase().split("");
      return le[code[0]].emoji + "" + le[code[1]].emoji;
    }
    return null;
  };

  render() {
    const cruise = this.props.cruise;
    const yacht = cruise.yacht;
    const crewCount = cruise.crew.length + 1;
    return (
      <div>
        {this.state.redirect}

        <h1 className="ui header">{cruise.name}</h1>

        <div className="ui segment">
          <div className="content">
            <div className="meta">
              <p>
                <Link to={`/yachts/${yacht.id}`}>
                  {this.emojiFlag(yacht.nationality)} {yacht.name}
                </Link>
              </p>
              <p>{this.renderCruiseDates(cruise)}</p>
            </div>
          </div>

          <div className="ui divider"></div>

          <div className="extra content">
            <Statistics
              stats={{
                crew: crewCount,
                miles: cruise.miles,
                fullDays: cruise.fullDays,
              }}
              includes={[CREW, FULL_DAYS]}
            />
          </div>
        </div>

        <div className="ui segment">
          <button
            className="ui blue labeled icon button"
            onClick={this.props.onRankingButtonClick}
          >
            <i className="list ol yellow icon"></i>
            <FormattedMessage
              id="action.showCruiseRanking"
              defaultMessage="Show ranking after {cruise}"
              values={{ cruise: cruise.name }}
            />
          </button>

          {hasRole("ADMIN") ? (
            <>
              <Link className="ui button" to={`/cruises/${cruise.id}/edit`}>
                <FormattedMessage id="action.edit" defaultMessage="Edit" />
              </Link>
              {/* <button className="ui button" onClick={this.props.onRankingButtonClick}>
                <FormattedMessage id="action.publishCruise" defaultMessage="Publish" />
              </button>
              <button className="ui button" onClick={this.props.onRankingButtonClick}>
                <FormattedMessage id="action.unpublishCruise" defaultMessage="Unpublish" />
              </button> */}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default CruiseCard;
