import milesrank from './milesrank';

const UsersMethods = {

  findByToken: (token, recaptchaToken) => {
    return milesrank.get("/api/public/users", {
      params: {
        token: token
      },
      headers: {
        'X-reCAPTCHA-Token': recaptchaToken
      }
    });
  },

  register: (user, recaptchaToken) => {

    return milesrank.post("/api/public/users", user, {
      validateStatus: status => status < 500,
      headers: {
        'X-reCAPTCHA-Token': recaptchaToken
      }
    });
  },

  getList: () => {
    return milesrank.get("/api/users");
  },

  getById: (id) => {
    return milesrank.get(`/api/users/${id}`);
  },

  create: (user) => {
    return milesrank.post(`/api/users`, user, {
      validateStatus: status => status < 500
    });
  },

  update: (user, userId) => {
    return milesrank.put(`/api/users/${userId}`, user, {
      validateStatus: status => status < 500
    });
  },

  delete: (userId) => {
    return milesrank.delete(`/api/users/${userId}`, {
      validateStatus: status => status < 500
    });
  },

  sendRegistrationCode: (userId) => {
    return milesrank.post(`/api/users/${userId}/send-code`, {
      validateStatus: status => status < 500
    });
  }

}

export default UsersMethods;