import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate, useParams } from "react-router";
import CruiseForm from "./CruiseForm";
import cruises from "../../apis/cruises";
import yachts from "../../apis/yachts";
import people from "../../apis/people";
import apiUtils from "../../apis/utils";

const CruiseEditPage = () => {

  const { cruiseId } = useParams();

  const [cruise, setCruise] = useState(null);
  const [yachtList, setYachtList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [redirect, setRedirect] = useState('');

  const clearErrors = () => {
    setErrors([]);
    setErrorMessages([]);
  }

  useEffect(() => {
    cruises.getById(cruiseId)
      .then(({data}) => setCruise(data))
      .catch(error => console.error("Error while loading cruise", error));
  }, [cruiseId]);

  useEffect(() => yachts.get().then(response => setYachtList(response.data.yachts)), []);

  const onSubmit = cruise => {
    cruises.update(cruiseId, cruise)
      .then(apiUtils.onResponse(
        data => setRedirect(<Navigate to={`/cruises/${cruiseId}`} />),
        data => handleValidationErrors(data),
        data => handleClientError(data)
      ))
      .catch(apiUtils.onError(handleError));
  }

  const onPersonSearch = value => {
    if (value.length < 3) 
      return new Promise((resolve) => resolve([]));
      
    return people.find(value)
      .then(response => response.data.results);
  };
  
  const onAddNewPerson = person => {
    return people.save(person)
      .then(apiUtils.onCreateResponse(
        newId => {        
          clearErrors();
          return { ...person, id: newId };
        }, 
        data => handleValidationErrors(data),
        data => handleClientError(data)
      ))
      .catch(apiUtils.onError(handleError));
  };

  const handleValidationErrors = (data) => {
    console.log("validation errors response", data);
    let errors = [];
    for (let key in data.errors)
      if (data.errors.hasOwnProperty(key)) errors.push(data.errors[key]);
    console.log("validation errors", errors);
    setErrors(data.errors);
  };

  const handleClientError = (data) => {
    console.log("handleClientError", data);
    setErrors({ global: data.message });
    setErrorMessages([data.message]);
  };

  const handleError = error => {
    let message = "Oho! Nieoczekiwany błąd :(";
    setErrors({ global: message });
    setErrorMessages([message]);
  };


  return (

    <div className="ui component">

      {redirect}

      <h1 className="ui header">
        <FormattedMessage id="title.editCruise" defaultMessage="Edit cruise" />
      </h1>

      {cruise 
        ? <CruiseForm 
            cruise={cruise}
            yachts={yachtList}
            errors={errors}
            errorMessages={errorMessages}
            onCrewSearch={onPersonSearch} 
            onCaptainSearch={onPersonSearch}
            onAddNewPerson={onAddNewPerson}
            onSubmit={onSubmit}
          /> 
        : ''}

      <div><p>{JSON.stringify()}</p></div>
    </div>
  );
}

export default CruiseEditPage;
