import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import users from "../../apis/users";
import UserForm from "./UserForm";
import apiUtils from "../../apis/utils";

const UserEditPage = () => {

  const { userId } = useParams();
  const [errors, setErrors] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const onSaveButtonClicked = (user, userId) => {
    users.update(user, userId)
      .then(apiUtils.onResponse(
        data => navigate(`/users`),
        data => handleValidationErrors(data),
        data => handleClientError(data)
      ))
      .catch(apiUtils.onError(handleError));
  }

  const handleValidationErrors = (data) => {
    console.log("validation errors response", data);
    let errors = [];
    for (let key in data.errors)
      if (data.errors.hasOwnProperty(key)) errors.push(data.errors[key]);
    console.log("validation errors", errors);
    setErrors(data.errors);
  };

  const handleClientError = (data) => {
    console.log("handleClientError", data);
    setErrors({ global: data.message });
    setErrorMessages([data.message]);
  };

  const handleError = error => {
    let message = "Oho! Nieoczekiwany błąd :(";
    setErrors({ global: message });
    setErrorMessages([message]);
  };

  useEffect(() => {

    const loadUser = (userId) => {
      users.getById(userId)
        .then(({data}) => {
          setUser({...data});
        })
        .catch(error => console.log(error));
    }


    loadUser(userId);

  }, [userId]);


  return (
    <UserForm user={user} errors={errors} errorMessages={errorMessages} title="editPerson" onSave={onSaveButtonClicked} />
  );
};

export default UserEditPage;