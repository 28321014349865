import React from "react";
import { FormattedMessage } from "react-intl";

const RankingPositionChange = (props) => {  
  const change = props.change;
  if (change > 0)
    return <span className="ui floating green label rank-change-up">+{change}</span>;
  if (change < 0)
    return <span className="ui floating red label rank-change-down">{change}</span>;
  if (props.debut) {
    return (
      <span className="ui floating ribbon orange label rank-debut">
        <FormattedMessage id="label.debut" defaultMessage="Debut" />
      </span>
    );
  }  
  return null;
  
}

export default RankingPositionChange;
