import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { hasRole } from "../../core/Auth";
import LoadingError from "../common/LoadingError";
import UsersTable from "./UsersTable";
import users from "../../apis/users";
import UserDeleteModal from "./UserDeleteModal";

const UsersPage = () => {

  const [usersList, setUsersList] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [loadingError, setLoadingError] = useState(false);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState("");

  const loadUsers = () => {
    setPageLoading(true);
    users.getList()
      .then(({ data }) => setUsersList(data.users))
      .catch(error => {
        setLoadingError(true);
        setLoadingErrorMessage(<FormattedMessage
          id="error.loading.users"
          defaultMessage="Whoops! Something went wrong :("
        />);
      })
      .finally(() => setPageLoading(false));
  }

  useEffect(() => {
    loadUsers();
  }, []);

  const sendRegistrationCode = userId => {
    setPageLoading(true);
    users.sendRegistrationCode(userId)
    .then(() => registrationCodeSent(userId))
    .catch(error => {
      setLoadingError(true);
      setLoadingErrorMessage(<FormattedMessage
        id={`error.${error.response.data.errorCode}`}
        defaultMessage="Whoops! Something went wrong :("
      />);
    })
    .finally(() => {
      setPageLoading(false);
    });
  }

  const registrationCodeSent = userId => {
    console.log('registration code sent');
  }

  const userDeleted = (userId) => {
    console.log(`user ${userId} deleted`);
    loadUsers();
  };

  const onUserDeleteClick = userId => {
    setUserToDelete(usersList.find(c => c.id === userId));
    openModal();
  }

  const onUserDeleteConfirm = () => {
    setPageLoading(true);
    users.delete(userToDelete.id)
      .then(() => userDeleted(userToDelete.id))
      .catch(error => console.log(error))
      .finally(() => {
        closeModal();
        setPageLoading(false);
      });
  }
  
  const openModal = () => setModalOpen(true);

  const closeModal = () => {
    setModalOpen(false); 
    setUserToDelete({});
  }

  return (
    <>
      <h1 className="ui header">
        <FormattedMessage id="title.users" defaultMessage="Users" />
      </h1>

      <Dimmer.Dimmable as={Segment} dimmed={pageLoading}>
        <Dimmer active={pageLoading} inverted>
          <Loader>
            <FormattedMessage
              id="loading.users"
              defaultMessage="Loading users"
            />
          </Loader>
        </Dimmer>

        {loadingError ? (
          <LoadingError message={loadingErrorMessage} />
        ) : (
          <>
            {hasRole('ADMIN') 
              ? <Link to={`/users/add`} className="ui primary right labeled button icon" style={{marginBottom: '1em'}}>
                  <i className="ui icon plus"></i>
                  <FormattedMessage id="action.addNewPerson" defaultMessage="Add new person" />
                </Link> 
              : ''
            }
            <UsersTable users={usersList} onUserDeleteClick={onUserDeleteClick} onSendCodeClick={sendRegistrationCode} />
          </>
        )}

      </Dimmer.Dimmable>

      <UserDeleteModal
        user={userToDelete} 
        modalOpened={modalOpen}
        onCloseModal={closeModal}
        onOpenModal={openModal}
        onUserDeleteConfirm={onUserDeleteConfirm}
      />
    </>
  );
}

export default UsersPage;