import React from "react";
import { FormattedMessage } from "react-intl";
import { hasRole } from "../../core/Auth";
import CruiseSimpleRow from "./CruiseSimpleRow";
import Pagination from "../common/MilesrankPagination";
import { Input } from "semantic-ui-react";
import { useMobileDetection } from "../../hooks/MobileDetecton";

const CruisesPagedTable = ({ cruisesPage, query, onPageChange, onSearchInputChange, onCruiseDeleteClick, onCruisePublishClick, onCruiseUnpublishClick }) => {

  const isMobile = useMobileDetection();

  const cruiseRows = cruisesPage.cruises.map(cruise => 
    <CruiseSimpleRow 
        key={cruise.id} 
        cruise={cruise} 
        onCruiseDeleteClick={onCruiseDeleteClick}
        onCruisePublishClick={onCruisePublishClick}
        onCruiseUnpublishClick={onCruiseUnpublishClick} />);

  return (
    <table className="ui celled padded table" style={{ marginTop: 0 }}>
      <thead>
        <tr>
          <th colSpan={hasRole('ADMIN') ? 7 : 6} style={{ textAlign: "center" }}>
            <Pagination
                onPageChange={onPageChange}
                activePage={cruisesPage.pageNumber}
                totalPages={cruisesPage.totalPagesCount}
              />
          </th>
        </tr>
        <tr>
          <th colSpan={hasRole('ADMIN') ? 7 : 6}>
            <FormattedMessage id="action.searchCruise" defaultMessage="Search for a cruise">
              {msg => 
                <Input
                  className="ui item"
                  tabIndex="0"
                  label={isMobile ? null : `${msg}:`}
                  placeholder={isMobile ? msg : null}
                  value={query}
                  onChange={onSearchInputChange}
                  icon="search"
                />
              }
            </FormattedMessage>  
          </th>
        </tr>
        <tr>
          <th>
            <FormattedMessage id="header.cruiseName" defaultMessage="Name" />
          </th>
          <th>
            <FormattedMessage id="header.start" defaultMessage="Start" />
          </th>
          <th>
            <FormattedMessage id="header.end" defaultMessage="End" />
          </th>
          <th>
            <FormattedMessage id="header.yacht" defaultMessage="Yacht" />
          </th>
          <th>
            <FormattedMessage id="header.crew" defaultMessage="Crew" />
          </th>
          <th className="right aligned">
            <FormattedMessage id="header.miles" defaultMessage="Miles" />
          </th>
          {hasRole('ADMIN') ? <th></th> : null}
        </tr>
      </thead>
      <tbody>{cruiseRows}</tbody>
      <tfoot>
        <tr>
          <th colSpan={hasRole('ADMIN') ? 7 : 6} style={{ textAlign: "center" }}>
            <Pagination
                onPageChange={onPageChange}
                activePage={cruisesPage.pageNumber}
                totalPages={cruisesPage.totalPagesCount}
              />
          </th>
        </tr>
      </tfoot>
    </table>
  );
}

export default CruisesPagedTable;
