import React, { useRef, useState } from "react";
import { Checkbox } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage } from "react-intl";
import './RegisterForm.css';

const RegisterForm = (props) => {

  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [consent, setConsent] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const recaptchaRef = useRef(null);

  const onSignUp = () => {
    props.onFormSubmit({
      email: email,
      password: password,
      registrationToken: props.token,
      gdprConsent: consent,
      firstName: firstName,
      lastName: lastName
    }, recaptchaRef);
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <div>
      <h1 className="ui header">
        <FormattedMessage id="title.signUp" defaultMessage="Sign up" />
      </h1>

      <div className="ui error message" hidden={props.errorMessages.length < 1}>
        <div className="header">
          <FormattedMessage
            id="msg.errorsOccured"
            defaultMessage="There were some errors"
          />
        </div>
        <ul className="list">
          {props.errorMessages
          .flatMap(msg => msg)
          .map((mmsg, i) => <li key={i}>{mmsg}</li>)}
        </ul>
      </div>

      <div className="ui secondary segment">
        <form className="ui big form" onSubmit={e => e.preventDefault()}>
          <div className="two fields">
            <div className={`field ${props.errors?.firstName ? 'error' : ''}`}>
              <label><FormattedMessage id="field.firstName" defaultMessage="First name" /></label>
              <input type="text" name="first-name" value={firstName} onChange={e => setFirstName(e.target.value)} />
            </div>
            <div className={`field ${props.errors?.lastName ? 'error' : ''}`}>
              <label><FormattedMessage id="field.lastName" defaultMessage="Last name" /></label>
              <input type="text" name="last-name" value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
          </div>
          <div className={`field ${props.errors?.email ? 'error' : ''}`}>
            <label><FormattedMessage id="field.email" defaultMessage="E-mail address" /></label>
            <input type="email" name="email" placeholder="user@example.com" value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className={`field ${props.errors?.password ? 'error' : ''}`}>
            <label><FormattedMessage id="field.password" defaultMessage="Password" /></label>
            <div className="ui icon input mr-password">
              <input type={passwordVisible ? `text` : `password`} name="password" value={password} onChange={e => setPassword(e.target.value)} />
              <FormattedMessage id={passwordVisible ? `tooltip.hidePassword` : `tooltip.showPassword`} defaultMessage={passwordVisible ? `Hide password` : `Show password`}>
                {msg => <i 
                  aria-hidden="true" 
                  className={(passwordVisible ? `eye slash` : `eye`) + ` icon link`}
                  title={msg}
                  onClick={togglePasswordVisibility} />}
              </FormattedMessage>  
            </div>
          </div>
          <div className="field">
            <Checkbox
              label={
              <label>
                <FormattedMessage 
                  id="field.consent" 
                  defaultMessage="I read and agree to the <a>Terms and Conditions</a>" 
                  values={{
                    a: chunks => 
                    <a 
                      target="_blank" 
                      rel="noreferrer" 
                      href="https://kanonsailing.pl/wp-content/uploads/2021/11/Klauzula-informacyjna.pdf">
                        {chunks}
                      </a>
                  }} />
              </label>}
              onChange={e => setConsent(!consent)}
              defaultChecked={consent} />
          </div>
          <div>
            <ReCAPTCHA 
              ref={recaptchaRef}
              sitekey={props.reCaptchaSiteKey} 
              size="invisible" 
              grecaptcha={window.grecaptcha}/>
          </div>
          <button className={(props.loading ? `loading` : null) + ` ui primary button`} type="submit" disabled={!consent || props.loading} onClick={onSignUp}>
            <FormattedMessage id="action.signUp" defaultMessage="Sign up" />
          </button>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;