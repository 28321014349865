import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate } from "react-router";
import CruiseForm from "./CruiseForm";
import cruises from "../../apis/cruises";
import yachts from "../../apis/yachts";
import people from "../../apis/people";
import apiUtils from "../../apis/utils";

const CruiseAddPage = () => {

  const [yachtList, setYachtList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [redirect, setRedirect] = useState('');

  const clearErrors = () => {
    setErrors([]);
    setErrorMessages([]);
  }

  useEffect(() => yachts.get().then(response => setYachtList(response.data.yachts)), []);

  const onSubmit = cruise => {
    cruises.save(cruise)
      .then(apiUtils.onCreateResponse(
        newId => setRedirect(<Navigate to={`/cruises/${newId}`} />),
        data  => handleValidationErrors(data),
        data  => handleClientError(data)
      ))
      .catch(apiUtils.onError(handleError));
  }

  const onPersonSearch = value => {
    if (value.length < 3) 
      return new Promise(() => []);
      
    return people.find(value)
      .then(response => response.data.results);
  };
  
  const onAddNewPerson = person => {
    return people.save(person)
      .then(apiUtils.onCreateResponse(
        newId => {        
          clearErrors();
          return { ...person, id: newId };
        }, 
        data => handleValidationErrors(data),
        data => handleClientError(data)
      ))
      .catch(apiUtils.onError(handleError));
  };

  const handleValidationErrors = (data) => {
    console.log("validation errors response", data);
    let errors = [];
    for (let key in data.errors)
      if (data.errors.hasOwnProperty(key)) errors.push(data.errors[key]);
    console.log("validation errors", errors);
    setErrors(data.errors);
    // end processing somehow without throwing
  };

  const handleClientError = (data) => {
    console.log("handleClientError", data);
    setErrors({ global: data.message });
    setErrorMessages([data.message]);
    // end processing somehow without throwing
  };

  const handleError = error => {
    let message = "Oho! Nieoczekiwany błąd :(";
    setErrors({ global: message });
    setErrorMessages([message]);
  };


  return (

    <div className="ui component">

      {redirect}

      <h1 className="ui header">
        <FormattedMessage id="title.addNewCruise" defaultMessage="Add new cruise" />
      </h1>

      <CruiseForm 
        yachts={yachtList}
        errors={errors}
        errorMessages={errorMessages}
        onCrewSearch={onPersonSearch} 
        onCaptainSearch={onPersonSearch}
        onAddNewPerson={onAddNewPerson}
        onSubmit={onSubmit}
      />

    </div>
  );
}

export default CruiseAddPage;
