import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import {
  NavLink,
  Link
} from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { hasRole } from "../../core/Auth";
import AppRoutes from "./AppRoutes";

const DesktopLayout = ({ onLogout, isAuthenticated }) => {

  const logoutClicked = e => {
    e.preventDefault();
    onLogout();
  }

  return (

      <Fragment>

        <div
          id="milesrank-main-menu"
          className="ui stackable container inverted menu"
        >
          <div className="header item">
            <Link to="/" className="logo-link">
              <img className="kanon-logo" src="/static/icon/logo-kst-orange.svg" height="55" alt="KanonSailing.pl" />
            </Link>
          </div>
          <Dropdown item text="Rankingi">
            <Dropdown.Menu>
              <NavLink to="/">
                <Dropdown.Item>              
                  <FormattedMessage id="menu.ranking.general" defaultMessage="General Ranking" />              
                </Dropdown.Item>
              </NavLink>
              <NavLink to="/?year=2023">
                <Dropdown.Item>              
                  <FormattedMessage id="menu.ranking.yearly" defaultMessage="Yearly Ranking" />              
                </Dropdown.Item>
              </NavLink>
            </Dropdown.Menu>
          </Dropdown>
          <NavLink to="/cruises" className="item">
            <FormattedMessage id="menu.cruises" defaultMessage="Cruises" />
          </NavLink>
          <NavLink to="/yachts" className="item">
            <FormattedMessage id="menu.yachts" defaultMessage="Yachts" />
          </NavLink>
          {hasRole('ADMIN') ? (
              <NavLink to="/users" className="item">
                <FormattedMessage id="menu.users" defaultMessage="Users" />
              </NavLink>
          ) : null}

          {isAuthenticated ? (
            <Link to="/logout" className="floated right item" style={{cursor: "pointer"}} onClick={logoutClicked}>
              <i className="sign-out icon"></i>
              <FormattedMessage id="action.logout" defaultMessage="Log out" />
            </Link>
          ) : null}
        </div>
        <div className="ui container">
          <AppRoutes />
        </div>

      </Fragment>
  );

};

export default DesktopLayout;