import React, { useCallback, useEffect, useState } from "react";
import YachtCard from "./YachtCard";
import YachtCruises from "./YachtCruises";
import yachts from "../../apis/yachts";
import LoadingError from "../common/LoadingError";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";

const YachtDetailsPage = () => {

  const { yachtId } = useParams();

  const [yachtLoading, setYachtLoading] = useState(false);
  const [yacht, setYacht] = useState({});
  const [yachtStats, setYachtStats] = useState({});

  const [cruisesLoading, setCruisesLoading] = useState(false);
  const [yachtCruises, setYachtCruises] = useState([]);

  const [yachtError, setYachtError] = useState(false);
  const [yachtErrorMessage, setYachtErrorMessage] = useState('');

  const [cruisesError, setCruisesError] = useState(false);
  const [cruisesErrorMessage, setCruisesErrorMessage] = useState('');

  const onYachtError = useCallback(error => {
    const errorMessage = (
      <LoadingError
        message={
          <FormattedMessage
            id="error.loading.yacht"
            defaultMessage="Whoops! Something went wrong :("
          />
        }
      />
    );
    setYachtError(true);
    setYachtErrorMessage(errorMessage);
  }, []);

  const onCruisesError = useCallback(error => {
    const errorMessage = (
      <LoadingError
        message={
          <FormattedMessage
            id="error.loading.cruises"
            defaultMessage="Whoops! Something went wrong :("
          />
        }
      />
    );
    setCruisesError(true);
    setCruisesErrorMessage(errorMessage);
  }, []);

  useEffect(() => {

    setYachtLoading(true);
    Promise.allSettled([
      yachts.getById(yachtId),
      yachts.getStats(yachtId)
    ])
      .then(([yachtResponse, statsResponse]) => {
        if (yachtResponse.status === 'fulfilled') 
          setYacht(yachtResponse.value.data);
        if (statsResponse.status === 'fulfilled') 
          setYachtStats(statsResponse.value.data);
      })
      .catch(onYachtError)
      .finally(() => setYachtLoading(false));

    setCruisesLoading(true);
    yachts.getYachtCruises(yachtId)
      .then(({data}) => setYachtCruises(data.cruises))
      .catch(error => {
        console.error(error)
      })
      .finally(() => setCruisesLoading(false));

  }, [yachtId, onYachtError, onCruisesError])

  return (
    <div>
      {yachtError ? (
        yachtErrorMessage
      ) : (
        <YachtCard 
          loading={yachtLoading} 
          yacht={yacht} 
          stats={yachtStats} />
      )}

      {cruisesError ? (
        cruisesErrorMessage
      ) : (
        <YachtCruises
          loading={cruisesLoading}
          cruises={yachtCruises}
        />
      )}
    </div>    
  );

}

export default YachtDetailsPage;