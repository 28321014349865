import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

const UserDeleteModal = ({ user, modalOpened, onCloseModal, onOpenModal, onUserDeleteConfirm }) => {

  return (

    <Modal basic
      onClose={onCloseModal}
      onOpen={onOpenModal}
      open={modalOpened}
      size='small'
    >
      <Header icon>
        <Icon name='delete' />
          <FormattedMessage id="confirm.deleteUser.title" defaultMessage="Are you sure you want to delete cruise {userName}?" values={{userName: `${user.firstName} ${user.lastName}`}} />
      </Header>
      <Modal.Content>
        <p>
          <FormattedMessage id="confirm.deleteUser.text" defaultMessage="If you delete {userName} there is no going back." values={{userName: `${user.firstName} ${user.lastName}`}} />
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' inverted onClick={onCloseModal}>
          <Icon name='remove' /> <FormattedMessage id="action.cancelDelete" defaultMessage="No, cancel" />
        </Button>
        <Button color='red' inverted onClick={onUserDeleteConfirm}>
          <Icon name='checkmark' /> <FormattedMessage id="action.confirmDelete" defaultMessage="Yes, delete it" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default UserDeleteModal;