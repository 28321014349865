import React from "react";
import { FormattedMessage } from "react-intl";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import CruiseRow from "../common/CruiseRow";

const YachtCruises = ({loading, cruises}) => {

  const cruiseRows = cruises.map(cruise => (
    <CruiseRow
      key={cruise.id}
      cruise={cruise}
    />
  ));

  return (
    <Dimmer.Dimmable as={Segment} dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader>
          <FormattedMessage
            id="loading.yacht.cruises"
            defaultMessage="Loading yacht cruises"
          />
        </Loader>
      </Dimmer>
      <table className="ui celled padded table" style={{ marginTop: 0 }}>
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="header.cruiseName"
                defaultMessage="Cruise name"
              />
            </th>
            <th>
              <FormattedMessage
                id="header.start"
                defaultMessage="Start date"
              />
            </th>
            <th>
              <FormattedMessage
                id="header.end"
                defaultMessage="End date"
              />
            </th>
            <th>
              <FormattedMessage
                id="header.captain"
                defaultMessage="Captain"
              />
            </th>
            <th className="right aligned">
              <FormattedMessage id="header.miles" defaultMessage="Miles" />
            </th>
          </tr>
        </thead>
        <tbody>{cruiseRows}</tbody>
      </table>
    </Dimmer.Dimmable>
  );

}

export default YachtCruises;