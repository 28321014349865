import milesrank from './milesrank';

const CruisesMethods = {

  getCruisesPage: (pageNumber, pageSize, query) => {
    return milesrank.get("/api/cruises/paged", {
      params: {
        pageNo: pageNumber,
        pageSize: pageSize,
        query: query === undefined ? "" : query
      }
    });
  },

  getById: (id) => {
    return milesrank.get("/api/cruises/" + id);
  },

  getYears: () => {
    return milesrank.get("/api/cruises/years");
  },

  getPersonCruises: (personId) => {
    return milesrank.get("/api/people/" + personId + "/cruises");
  },

  save: (cruise) => {
    return milesrank.post("/api/cruises", cruise, {
      validateStatus: (status) => status < 500
    });
  },

  update: (id, cruise) => {
    return milesrank.put("/api/cruises/" + id, cruise, {
      validateStatus: (status) => status < 500
    });
  },

  publish: (id) => {
    return milesrank.post("/api/cruises?status=published", {id: id}, {
      validateStatus: (status) => status < 500
    });
  },

  unpublish: (id) => {
    return milesrank.post("/api/cruises?status=unpublished", {id: id}, {
      validateStatus: (status) => status < 500
    });
  },

  delete: (id) => {
    return milesrank.delete("/api/cruises/" + id);
  },

}

export default CruisesMethods;