import React from "react";
import { Icon, Pagination } from "semantic-ui-react";
import { useMobileDetection } from "../../hooks/MobileDetecton";

const MilesrankPagination = ({ activePage, totalPages, onPageChange }) => {

  const isMobile = useMobileDetection();

  return isMobile 

      ? <Pagination
          boundaryRange={0}
          siblingRange={0}
          size="mini"
          onPageChange={onPageChange}
          activePage={activePage}
          ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
          firstItem={{ content: <Icon name='angle double left' />, icon: true }}
          lastItem={{ content: <Icon name='angle double right' />, icon: true }}
          prevItem={{ content: <Icon name='angle left' />, icon: true }}
          nextItem={{ content: <Icon name='angle right' />, icon: true }}
          totalPages={totalPages} /> 

      : <Pagination
          onPageChange={onPageChange}
          activePage={activePage}
          ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
          firstItem={{ content: <Icon name='angle double left' />, icon: true }}
          lastItem={{ content: <Icon name='angle double right' />, icon: true }}
          prevItem={{ content: <Icon name='angle left' />, icon: true }}
          nextItem={{ content: <Icon name='angle right' />, icon: true }}
          totalPages={totalPages} />
}

export default MilesrankPagination;